// Specifies content for each checkpoint
export const routeLineGeojson = [
// DC to Mexcio City
  [-77.03691, 38.90717],
  [-77.12543, 38.8803],
  [-77.23729, 38.88071],
  [-77.44742, 38.83698],
  [-77.51237, 38.80213],
  [-77.63196, 38.81428],
  [-77.7385, 38.8339],
  [-77.90577, 38.88239],
  [-78.12501, 38.92068],
  [-78.29983, 39.00973],
  [-78.45795, 38.9499],
  [-78.58015, 38.82717],
  [-78.68131, 38.64072],
  [-78.83812, 38.44978],
  [-78.91285, 38.39274],
  [-78.93911, 38.26694],
  [-79.09612, 38.07129],
  [-79.26878, 37.90132],
  [-79.38888, 37.80794],
  [-79.49766, 37.67786],
  [-79.64577, 37.57656],
  [-79.81903, 37.46468],
  [-79.91147, 37.37602],
  [-80.02789, 37.32985],
  [-80.25344, 37.22571],
  [-80.32434, 37.15687],
  [-80.45766, 37.10611],
  [-80.70055, 37.07506],
  [-80.77121, 37.00072],
  [-80.8844, 36.94988],
  [-81.06374, 36.9607],
  [-81.39842, 36.87517],
  [-81.88445, 36.74748],
  [-82.10263, 36.63813],
  [-82.37662, 36.53848],
  [-82.5363, 36.44597],
  [-82.78729, 36.34444],
  [-83.19996, 36.17981],
  [-83.46246, 36.0276],
  [-83.64554, 35.98597],
  [-83.81287, 36.00779],
  [-83.96642, 35.95895],
  [-84.26902, 35.849],
  [-84.47339, 35.63935],
  [-84.75629, 35.33434],
  [-84.9626, 35.14766],
  [-85.20916, 35.00608],
  [-85.38256, 35.01954],
  [-85.47186, 34.94639],
  [-85.54458, 34.78973],
  [-85.56244, 34.70406],
  [-85.65249, 34.58182],
  [-85.80742, 34.36431],
  [-85.99226, 34.13799],
  [-86.07211, 34.03671],
  [-86.09182, 33.99187],
  [-86.33319, 33.83224],
  [-86.46258, 33.77023],
  [-86.52263, 33.70548],
  [-86.61834, 33.63956],
  [-86.64229, 33.54881],
  [-86.78125, 33.39589],
  [-86.99452, 33.33219],
  [-87.0758, 33.29553],
  [-87.16537, 33.23247],
  [-87.43699, 33.1723],
  [-87.64912, 33.12359],
  [-87.97437, 32.83249],
  [-88.11174, 32.72734],
  [-88.25077, 32.56918],
  [-88.39272, 32.45317],
  [-88.66789, 32.37189],
  [-88.77189, 32.33405],
  [-88.82044, 32.26064],
  [-88.91954, 32.04536],
  [-89.00021, 31.92816],
  [-89.05479, 31.79232],
  [-89.21059, 31.61006],
  [-89.32213, 31.45488],
  [-89.35256, 31.28341],
  [-89.3583, 31.15852],
  [-89.42701, 30.98511],
  [-89.53648, 30.7384],
  [-89.67575, 30.48295],
  [-89.73784, 30.32635],
  [-89.89875, 30.34093],
  [-90.23855, 30.47956],
  [-90.56186, 30.47911],
  [-91.09566, 30.41708],
  [-91.29622, 30.45236],
  [-91.78935, 30.32446],
  [-91.97295, 30.27415],
  [-92.22954, 30.25002],
  [-92.60233, 30.23647],
  [-93.14657, 30.2448],
  [-93.55896, 30.18739],
  [-93.73135, 30.11869],
  [-93.85805, 30.12977],
  [-94.03052, 30.12204],
  [-94.13539, 30.07562],
  [-94.28385, 29.91941],
  [-94.41157, 29.82729],
  [-94.64812, 29.84013],
  [-94.92083, 29.82055],
  [-95.05821, 29.79133],
  [-95.29619, 29.77618],
  [-95.45616, 29.73013],
  [-95.62656, 29.59485],
  [-95.91317, 29.49355],
  [-96.07311, 29.3937],
  [-96.19349, 29.24471],
  [-96.38623, 29.12294],
  [-96.65987, 28.98266],
  [-96.96468, 28.77766],
  [-97.04694, 28.69486],
  [-97.04594, 28.51995],
  [-97.31683, 28.2574],
  [-97.52989, 28.01379],
  [-97.62395, 27.87998],
  [-97.65408, 27.79233],
  [-97.84103, 27.52489],
  [-97.83062, 27.35095],
  [-97.79312, 27.1334],
  [-97.76979, 26.74162],
  [-97.77313, 26.30862],
  [-97.71774, 26.18939],
  [-97.69429, 26.16816],
  [-97.7012, 26.11863],
  [-97.73909, 26.0216],
  [-97.79579, 25.83434],
  [-97.81592, 25.68132],
  [-97.81623, 25.46214],
  [-97.87132, 25.36809],
  [-97.86522, 25.2716],
  [-98.03436, 25.1062],
  [-98.09612, 24.97055],
  [-98.1079, 24.83325],
  [-98.14518, 24.79404],
  [-98.22687, 24.77176],
  [-98.28811, 24.64543],
  [-98.31346, 24.52293],
  [-98.38801, 24.39308],
  [-98.59314, 24.11944],
  [-98.85673, 24.12065],
  [-98.94345, 23.92892],
  [-99.03647, 23.87676],
  [-99.10701, 23.77483],
  [-99.09416, 23.71863],
  [-99.13624, 23.62384],
  [-99.1566, 23.58799],
  [-99.2769, 23.61285],
  [-99.33977, 23.58356],
  [-99.37185, 23.42509],
  [-99.54222, 23.34843],
  [-99.57568, 23.30183],
  [-99.62919, 23.2987],
  [-99.67866, 23.26441],
  [-99.67981, 23.20567],
  [-99.72959, 22.99689],
  [-99.84742, 22.84844],
  [-99.93211, 22.78144],
  [-100.11716, 22.62843],
  [-100.22608, 22.5754],
  [-100.29124, 22.49937],
  [-100.34019, 22.44347],
  [-100.42134, 22.49841],
  [-100.48812, 22.54947],
  [-100.6349, 22.56934],
  [-100.72385, 22.41265],
  [-100.78309, 22.3757],
  [-100.82014, 22.26919],
  [-100.83895, 22.06621],
  [-100.79999, 21.91138],
  [-100.73054, 21.83245],
  [-100.6835, 21.70138],
  [-100.74182, 21.62385],
  [-100.75001, 21.55801],
  [-100.7087, 21.44373],
  [-100.60718, 21.31467],
  [-100.51601, 21.0798],
  [-100.42756, 20.964],
  [-100.43254, 20.85042],
  [-100.40301, 20.76827],
  [-100.31218, 20.68188],
  [-100.12689, 20.49218],
  [-99.8213, 20.23429],
  [-99.71324, 20.11321],
  [-99.62508, 20.08246],
  [-99.46888, 19.99214],
  [-99.30322, 19.85747],
  [-99.23209, 19.79787],
  [-99.20062, 19.67342],
  [-99.20868, 19.5687],
  [-99.22933, 19.47443],
  [-99.20115, 19.42553],
  [-99.17083, 19.39957],
  [-99.13421, 19.42637],
  [-99.13722, 19.4306],











// Mexico city to panama




[-99.13722, 19.4306],
 [-99.13972, 19.42446],
 [-99.13407, 19.42371],
 [-99.13394, 19.42045],
 [-99.13666, 19.40313],
 [-99.14319, 19.36109],
 [-99.14553, 19.34868],
 [-99.13788, 19.32146],
 [-99.14621, 19.29616],
 [-99.16129, 19.28363],
 [-99.16557, 19.27439],
 [-99.16619, 19.26251],
 [-99.15597, 19.25263],
 [-99.14999, 19.24483],
 [-99.14415, 19.23362],
 [-99.14516, 19.22724],
 [-99.14092, 19.22405],
 [-99.1372, 19.2229],
 [-99.13154, 19.22831],
 [-99.12906, 19.22189],
 [-99.13432, 19.21284],
 [-99.13753, 19.2007],
 [-99.152, 19.19614],
 [-99.15786, 19.19354],
 [-99.15805, 19.18861],
 [-99.15127, 19.17317],
 [-99.14986, 19.16779],
 [-99.15389, 19.16447],
 [-99.15603, 19.15698],
 [-99.16469, 19.13959],
 [-99.18106, 19.11964],
 [-99.18699, 19.10833],
 [-99.2024, 19.09995],
 [-99.21541, 19.08973],
 [-99.22709, 19.08406],
 [-99.23211, 19.08112],
 [-99.23535, 19.07272],
 [-99.23796, 19.06623],
 [-99.23911, 19.05945],
 [-99.2399, 19.04894],
 [-99.21973, 19.03089],
 [-99.20542, 19.03222],
 [-99.1962, 19.02485],
 [-99.18147, 19.02815],
 [-99.15742, 19.02089],
 [-99.13733, 19.0214],
 [-99.15756, 19.01427],
 [-99.19001, 19.00181],
 [-99.20497, 18.98999],
 [-99.2207, 18.98214],
 [-99.23102, 18.98034],
 [-99.23456, 18.97412],
 [-99.24032, 18.97173],
 [-99.22322, 18.95719],
 [-99.21693, 18.9602],
 [-99.20277, 18.95676],
 [-99.19215, 18.95318],
 [-99.18762, 18.94753],
 [-99.1908, 18.93838],
 [-99.20173, 18.92861],
 [-99.20618, 18.91422],
 [-99.21938, 18.90148],
 [-99.22618, 18.89701],
 [-99.22867, 18.8888],
 [-99.22832, 18.87741],
 [-99.2247, 18.86795],
 [-99.22095, 18.8594],
 [-99.21897, 18.84669],
 [-99.21929, 18.81525],
 [-99.22249, 18.78027],
 [-99.25383, 18.73177],
 [-99.26465, 18.71913],
 [-99.2773, 18.69986],
 [-99.29044, 18.67963],
 [-99.29498, 18.66987],
 [-99.29524, 18.66074],
 [-99.29498, 18.6357],
 [-99.2884, 18.61117],
 [-99.2799, 18.6],
 [-99.26062, 18.58886],
 [-99.21301, 18.55977],
 [-99.19837, 18.52672],
 [-99.19074, 18.50139],
 [-99.18104, 18.48867],
 [-99.1741, 18.4765],
 [-99.17146, 18.45947],
 [-99.17338, 18.45069],
 [-99.17635, 18.43538],
 [-99.17825, 18.43033],
 [-99.17424, 18.4197],
 [-99.16993, 18.39742],
 [-99.1745, 18.38326],
 [-99.18067, 18.36746],
 [-99.1816, 18.35735],
 [-99.18475, 18.35207],
 [-99.19097, 18.33759],
 [-99.19947, 18.32376],
 [-99.20188, 18.30982],
 [-99.20135, 18.30221],
 [-99.20659, 18.29117],
 [-99.21019, 18.28251],
 [-99.20752, 18.27456],
 [-99.2057, 18.26741],
 [-99.21276, 18.25911],
 [-99.2147, 18.25171],
 [-99.21338, 18.24129],
 [-99.21739, 18.22126],
 [-99.20743, 18.20293],
 [-99.20366, 18.18835],
 [-99.21073, 18.17482],
 [-99.21301, 18.15602],
 [-99.20903, 18.13894],
 [-99.20848, 18.12491],
 [-99.2053, 18.11555],
 [-99.20624, 18.10669],
 [-99.2117, 18.09757],
 [-99.22228, 18.0922],
 [-99.22853, 18.086],
 [-99.22982, 18.07581],
 [-99.24598, 18.06449],
 [-99.25013, 18.05435],
 [-99.26069, 18.04371],
 [-99.27333, 18.02105],
 [-99.28614, 18.0057],
 [-99.31161, 17.98915],
 [-99.32308, 17.97852],
 [-99.33995, 17.9684],
 [-99.35886, 17.95065],
 [-99.3676, 17.94853],
 [-99.36754, 17.94067],
 [-99.37304, 17.93089],
 [-99.37304, 17.92017],
 [-99.38047, 17.91324],
 [-99.38346, 17.90552],
 [-99.39481, 17.8904],
 [-99.40205, 17.86706],
 [-99.40566, 17.859],
 [-99.40572, 17.851],
 [-99.40675, 17.84764],
 [-99.41297, 17.85075],
 [-99.42523, 17.85548],
 [-99.43671, 17.84651],
 [-99.45102, 17.83347],
 [-99.45878, 17.81876],
 [-99.47101, 17.80394],
 [-99.48136, 17.79799],
 [-99.48564, 17.78873],
 [-99.48025, 17.77178],
 [-99.48395, 17.76136],
 [-99.48976, 17.75435],
 [-99.48986, 17.74323],
 [-99.49972, 17.73393],
 [-99.50155, 17.72234],
 [-99.50953, 17.71307],
 [-99.5075, 17.6972],
 [-99.50507, 17.68979],
 [-99.49867, 17.68545],
 [-99.49445, 17.67801],
 [-99.49527, 17.6665],
 [-99.4971, 17.65925],
 [-99.50504, 17.65044],
 [-99.50405, 17.63929],
 [-99.50596, 17.63349],
 [-99.51845, 17.61929],
 [-99.5204, 17.6135],
 [-99.51788, 17.6084],
 [-99.51867, 17.59473],
 [-99.51356, 17.57568],
 [-99.50827, 17.54907],
 [-99.48895, 17.52591],
 [-99.48241, 17.51769],
 [-99.47897, 17.49871],
 [-99.47102, 17.47594],
 [-99.4781, 17.4517],
 [-99.47623, 17.43229],
 [-99.46657, 17.4235],
 [-99.46443, 17.41531],
 [-99.46194, 17.40489],
 [-99.46473, 17.3965],
 [-99.47618, 17.3765],
 [-99.48014, 17.36534],
 [-99.47841, 17.35728],
 [-99.47977, 17.33587],
 [-99.47514, 17.3263],
 [-99.4763, 17.32124],
 [-99.48144, 17.31969],
 [-99.48989, 17.31173],
 [-99.49648, 17.30444],
 [-99.50192, 17.3008],
 [-99.50486, 17.29017],
 [-99.51173, 17.26887],
 [-99.52124, 17.24817],
 [-99.52568, 17.2268],
 [-99.53108, 17.21455],
 [-99.53701, 17.21047],
 [-99.53743, 17.20392],
 [-99.53251, 17.19895],
 [-99.53307, 17.19262],
 [-99.5501, 17.18665],
 [-99.55439, 17.17066],
 [-99.55355, 17.15543],
 [-99.55271, 17.14038],
 [-99.56358, 17.12628],
 [-99.56944, 17.12262],
 [-99.56833, 17.11684],
 [-99.58502, 17.10303],
 [-99.59155, 17.10209],
 [-99.59323, 17.09791],
 [-99.59827, 17.08806],
 [-99.59969, 17.08116],
 [-99.60765, 17.07468],
 [-99.62018, 17.07354],
 [-99.63071, 17.06475],
 [-99.6339, 17.03853],
 [-99.63993, 17.0315],
 [-99.64901, 17.02637],
 [-99.66932, 17.00507],
 [-99.69806, 16.99544],
 [-99.70466, 16.98598],
 [-99.72949, 16.96935],
 [-99.75097, 16.95398],
 [-99.76276, 16.95018],
 [-99.76815, 16.9442],
 [-99.77996, 16.94318],
 [-99.78353, 16.93275],
 [-99.80066, 16.92885],
 [-99.81006, 16.92407],
 [-99.82479, 16.91295],
 [-99.82714, 16.90397],
 [-99.83579, 16.89046],
 [-99.83884, 16.88686],
 [-99.83503, 16.88575],
 [-99.82066, 16.88072],
 [-99.81123, 16.8683],
 [-99.81012, 16.8611],
 [-99.81335, 16.85797],
 [-99.81921, 16.85585],
 [-99.82267, 16.85219],
 [-99.82385, 16.85299],
 [-99.82385, 16.85299],
 [-99.82014, 16.85393],
 [-99.81838, 16.85728],
 [-99.81344, 16.85789],
 [-99.8086, 16.86176],
 [-99.80273, 16.85282],
 [-99.79129, 16.85012],
 [-99.78281, 16.83846],
 [-99.77814, 16.83184],
 [-99.7612, 16.83072],
 [-99.7446, 16.82949],
 [-99.73183, 16.82476],
 [-99.6987, 16.82354],
 [-99.66801, 16.81264],
 [-99.64529, 16.78682],
 [-99.63245, 16.77816],
 [-99.61555, 16.77453],
 [-99.61329, 16.77882],
 [-99.61013, 16.77585],
 [-99.60196, 16.77494],
 [-99.5957, 16.77148],
 [-99.59177, 16.76481],
 [-99.57473, 16.75521],
 [-99.56498, 16.76024],
 [-99.55934, 16.76721],
 [-99.55048, 16.77063],
 [-99.53633, 16.77073],
 [-99.52604, 16.76816],
 [-99.51772, 16.76777],
 [-99.51372, 16.77441],
 [-99.5062, 16.77527],
 [-99.49497, 16.77708],
 [-99.48536, 16.78077],
 [-99.4774, 16.7745],
 [-99.47098, 16.76992],
 [-99.46513, 16.77044],
 [-99.45523, 16.77603],
 [-99.43533, 16.77314],
 [-99.39932, 16.78207],
 [-99.39134, 16.7927],
 [-99.38128, 16.79222],
 [-99.37078, 16.79317],
 [-99.35923, 16.78911],
 [-99.34836, 16.78095],
 [-99.33314, 16.75413],
 [-99.30129, 16.74724],
 [-99.28078, 16.74699],
 [-99.25797, 16.74373],
 [-99.24176, 16.73767],
 [-99.23767, 16.74835],
 [-99.21989, 16.77573],
 [-99.2188, 16.78091],
 [-99.21077, 16.78264],
 [-99.2021, 16.78189],
 [-99.19594, 16.7759],
 [-99.18544, 16.76873],
 [-99.18675, 16.76011],
 [-99.1821, 16.75579],
 [-99.16728, 16.74673],
 [-99.16793, 16.73752],
 [-99.16374, 16.73077],
 [-99.1416, 16.72695],
 [-99.1317, 16.72477],
 [-99.12481, 16.71933],
 [-99.08141, 16.68433],
 [-99.07245, 16.67487],
 [-99.06228, 16.66965],
 [-99.05119, 16.66658],
 [-99.04529, 16.6586],
 [-99.00754, 16.61854],
 [-98.99904, 16.61111],
 [-98.98774, 16.60826],
 [-98.9762, 16.60311],
 [-98.96191, 16.60241],
 [-98.92762, 16.59429],
 [-98.90093, 16.58528],
 [-98.89052, 16.57697],
 [-98.87742, 16.5725],
 [-98.87185, 16.57394],
 [-98.85683, 16.57199],
 [-98.84932, 16.57519],
 [-98.82196, 16.58407],
 [-98.79032, 16.58098],
 [-98.78105, 16.58433],
 [-98.77541, 16.58965],
 [-98.76351, 16.60075],
 [-98.75144, 16.60473],
 [-98.69694, 16.61111],
 [-98.66929, 16.61468],
 [-98.6498, 16.61881],
 [-98.62281, 16.6336],
 [-98.56328, 16.64677],
 [-98.55095, 16.64455],
 [-98.54084, 16.64639],
 [-98.52594, 16.64324],
 [-98.51681, 16.64536],
 [-98.50616, 16.64653],
 [-98.50655, 16.65307],
 [-98.49987, 16.65495],
 [-98.48564, 16.62898],
 [-98.48564, 16.61081],
 [-98.4779, 16.60811],
 [-98.47174, 16.60606],
 [-98.46931, 16.60115],
 [-98.46598, 16.59363],
 [-98.46096, 16.58002],
 [-98.46275, 16.57063],
 [-98.4685, 16.56231],
 [-98.47481, 16.55287],
 [-98.47465, 16.54551],
 [-98.46597, 16.52147],
 [-98.46162, 16.51174],
 [-98.46411, 16.50649],
 [-98.44918, 16.48689],
 [-98.44188, 16.47932],
 [-98.43266, 16.47641],
 [-98.41527, 16.47147],
 [-98.40003, 16.46498],
 [-98.33491, 16.43977],
 [-98.31414, 16.4318],
 [-98.30505, 16.42353],
 [-98.28101, 16.3999],
 [-98.26148, 16.38243],
 [-98.25579, 16.38137],
 [-98.25024, 16.36769],
 [-98.24633, 16.35626],
 [-98.23932, 16.35011],
 [-98.23539, 16.34957],
 [-98.22968, 16.34519],
 [-98.22258, 16.34703],
 [-98.21217, 16.34482],
 [-98.20826, 16.35272],
 [-98.20448, 16.35529],
 [-98.19769, 16.35493],
 [-98.18585, 16.36126],
 [-98.18688, 16.37112],
 [-98.17851, 16.37287],
 [-98.17299, 16.37058],
 [-98.16643, 16.37321],
 [-98.15533, 16.37941],
 [-98.14472, 16.37881],
 [-98.13827, 16.37787],
 [-98.12589, 16.38606],
 [-98.11421, 16.38887],
 [-98.10344, 16.38651],
 [-98.09817, 16.38095],
 [-98.09243, 16.37546],
 [-98.08976, 16.37127],
 [-98.08412, 16.36323],
 [-98.0802, 16.36007],
 [-98.07136, 16.35802],
 [-98.05365, 16.35218],
 [-98.04515, 16.34587],
 [-98.0415, 16.33942],
 [-98.03514, 16.33459],
 [-98.02369, 16.32909],
 [-98.01406, 16.32904],
 [-98.00494, 16.33661],
 [-98.00238, 16.33087],
 [-97.99632, 16.32736],
 [-97.99134, 16.32921],
 [-97.9832, 16.33281],
 [-97.97449, 16.3335],
 [-97.96038, 16.34086],
 [-97.93157, 16.33366],
 [-97.90548, 16.3244],
 [-97.89609, 16.32373],
 [-97.87285, 16.30824],
 [-97.85438, 16.29641],
 [-97.8522, 16.2898],
 [-97.84562, 16.29056],
 [-97.84095, 16.28593],
 [-97.83305, 16.28454],
 [-97.82962, 16.28394],
 [-97.82443, 16.27949],
 [-97.81674, 16.26983],
 [-97.82121, 16.26598],
 [-97.82021, 16.26287],
 [-97.81397, 16.26206],
 [-97.8073, 16.25567],
 [-97.79942, 16.24747],
 [-97.80077, 16.24434],
 [-97.79364, 16.23937],
 [-97.78484, 16.2344],
 [-97.77916, 16.23152],
 [-97.77215, 16.22092],
 [-97.74858, 16.19032],
 [-97.74925, 16.18284],
 [-97.75565, 16.17304],
 [-97.74656, 16.16784],
 [-97.73388, 16.1622],
 [-97.73213, 16.15602],
 [-97.72275, 16.14853],
 [-97.71236, 16.1282],
 [-97.71133, 16.11383],
 [-97.70412, 16.10181],
 [-97.68476, 16.09165],
 [-97.63858, 16.08089],
 [-97.60823, 16.06415],
 [-97.59199, 16.05764],
 [-97.5819, 16.05517],
 [-97.58138, 16.04551],
 [-97.5685, 16.03072],
 [-97.52756, 16.00988],
 [-97.5189, 16.0059],
 [-97.50908, 16.00933],
 [-97.49022, 16.01722],
 [-97.45096, 16.01048],
 [-97.43587, 16.01042],
 [-97.42694, 16.00109],
 [-97.40107, 15.97353],
 [-97.38589, 15.96341],
 [-97.3697, 15.95927],
 [-97.35241, 15.95517],
 [-97.3446, 15.95637],
 [-97.33277, 15.95028],
 [-97.31745, 15.94732],
 [-97.31168, 15.95019],
 [-97.30681, 15.94705],
 [-97.29623, 15.94681],
 [-97.27762, 15.95021],
 [-97.25426, 15.95117],
 [-97.25221, 15.95861],
 [-97.24533, 15.95892],
 [-97.22647, 15.95436],
 [-97.21787, 15.95672],
 [-97.19686, 15.95277],
 [-97.17536, 15.94172],
 [-97.16226, 15.93935],
 [-97.15546, 15.93575],
 [-97.15262, 15.92921],
 [-97.12783, 15.91806],
 [-97.11871, 15.91031],
 [-97.1176, 15.90411],
 [-97.11713, 15.89106],
 [-97.10744, 15.88203],
 [-97.09199, 15.87187],
 [-97.08255, 15.86846],
 [-97.07933, 15.86935],
 [-97.07812, 15.87245],
 [-97.07671, 15.87202],
 [-97.07671, 15.87202],
 [-97.07996, 15.8675],
 [-97.07313, 15.8641],
 [-97.0603, 15.86209],
 [-97.04767, 15.84698],
 [-97.02914, 15.82698],
 [-97.02033, 15.82538],
 [-97.01079, 15.81606],
 [-96.95171, 15.79912],
 [-96.91033, 15.78532],
 [-96.8835, 15.77123],
 [-96.86657, 15.7691],
 [-96.8394, 15.76501],
 [-96.82618, 15.75989],
 [-96.81684, 15.75183],
 [-96.8081, 15.74036],
 [-96.79138, 15.73868],
 [-96.75364, 15.73348],
 [-96.71554, 15.72744],
 [-96.67836, 15.73238],
 [-96.64943, 15.72398],
 [-96.61725, 15.72291],
 [-96.60389, 15.71724],
 [-96.58115, 15.71663],
 [-96.55582, 15.72542],
 [-96.52381, 15.73241],
 [-96.48117, 15.7316],
 [-96.46058, 15.72305],
 [-96.44744, 15.72128],
 [-96.43398, 15.72091],
 [-96.42435, 15.72231],
 [-96.41881, 15.71938],
 [-96.41874, 15.71613],
 [-96.41224, 15.71656],
 [-96.40697, 15.71437],
 [-96.39145, 15.72195],
 [-96.36986, 15.72951],
 [-96.34749, 15.73379],
 [-96.33688, 15.73445],
 [-96.32802, 15.73982],
 [-96.3221, 15.73732],
 [-96.31424, 15.74003],
 [-96.30886, 15.74743],
 [-96.29881, 15.75086],
 [-96.28361, 15.75702],
 [-96.27769, 15.75737],
 [-96.26617, 15.76486],
 [-96.24563, 15.77207],
 [-96.22279, 15.77497],
 [-96.21325, 15.77933],
 [-96.20356, 15.77942],
 [-96.19827, 15.78342],
 [-96.18936, 15.78273],
 [-96.1805, 15.77862],
 [-96.1574, 15.78214],
 [-96.13792, 15.78869],
 [-96.12005, 15.79146],
 [-96.10936, 15.79478],
 [-96.09851, 15.796],
 [-96.08984, 15.80253],
 [-96.08347, 15.80636],
 [-96.08067, 15.80854],
 [-96.07237, 15.80629],
 [-96.0686, 15.81036],
 [-96.06991, 15.81651],
 [-96.0632, 15.82025],
 [-96.05205, 15.81888],
 [-96.04779, 15.81847],
 [-96.0423, 15.8135],
 [-96.02835, 15.81684],
 [-96.01723, 15.82438],
 [-96.01215, 15.82655],
 [-96.00895, 15.82308],
 [-96.00681, 15.82562],
 [-96.00161, 15.83835],
 [-96.00275, 15.84254],
 [-95.99836, 15.84587],
 [-95.99446, 15.84522],
 [-95.98634, 15.84742],
 [-95.97014, 15.85671],
 [-95.96298, 15.86179],
 [-95.96132, 15.86549],
 [-95.95371, 15.86834],
 [-95.94455, 15.87137],
 [-95.92985, 15.8755],
 [-95.90032, 15.87828],
 [-95.89212, 15.88118],
 [-95.88855, 15.88105],
 [-95.88927, 15.88407],
 [-95.88343, 15.89082],
 [-95.87134, 15.8915],
 [-95.86504, 15.89341],
 [-95.86135, 15.90023],
 [-95.8526, 15.90343],
 [-95.84293, 15.91094],
 [-95.82659, 15.9154],
 [-95.81409, 15.91853],
 [-95.80196, 15.91797],
 [-95.76889, 15.93127],
 [-95.75164, 15.94466],
 [-95.73435, 15.94952],
 [-95.72567, 15.95528],
 [-95.7285, 15.96342],
 [-95.71695, 15.98113],
 [-95.70055, 16.0008],
 [-95.68675, 15.99332],
 [-95.66848, 15.99106],
 [-95.64821, 15.98342],
 [-95.63351, 15.97943],
 [-95.62258, 15.98083],
 [-95.58655, 15.96921],
 [-95.56378, 15.96782],
 [-95.55515, 15.96583],
 [-95.54894, 15.96482],
 [-95.54762, 15.97001],
 [-95.54246, 15.97548],
 [-95.52226, 15.98176],
 [-95.51787, 15.98789],
 [-95.50755, 15.99064],
 [-95.49544, 15.98871],
 [-95.49373, 15.99334],
 [-95.48903, 15.9942],
 [-95.47475, 15.99806],
 [-95.45749, 15.99535],
 [-95.45118, 15.99924],
 [-95.44456, 15.99935],
 [-95.44535, 16.00638],
 [-95.4261, 16.02597],
 [-95.41805, 16.03302],
 [-95.40956, 16.03884],
 [-95.40857, 16.0429],
 [-95.40001, 16.04644],
 [-95.39817, 16.06643],
 [-95.40138, 16.07364],
 [-95.40812, 16.08016],
 [-95.40763, 16.08796],
 [-95.39882, 16.09491],
 [-95.35662, 16.10652],
 [-95.3417, 16.10483],
 [-95.32484, 16.10336],
 [-95.32015, 16.10187],
 [-95.31891, 16.10648],
 [-95.32073, 16.11825],
 [-95.31536, 16.12333],
 [-95.31033, 16.12579],
 [-95.30796, 16.13636],
 [-95.28201, 16.15207],
 [-95.28511, 16.15189],
 [-95.29491, 16.17395],
 [-95.30582, 16.18862],
 [-95.31404, 16.19471],
 [-95.31625, 16.20185],
 [-95.3189, 16.21374],
 [-95.32446, 16.22742],
 [-95.31154, 16.2561],
 [-95.29906, 16.28389],
 [-95.28101, 16.31596],
 [-95.27963, 16.3293],
 [-95.27355, 16.34258],
 [-95.25087, 16.37502],
 [-95.2422, 16.40141],
 [-95.23482, 16.41538],
 [-95.21109, 16.44132],
 [-95.19909, 16.46021],
 [-95.18827, 16.49151],
 [-95.15704, 16.53469],
 [-95.12515, 16.57405],
 [-95.10404, 16.59854],
 [-95.06646, 16.60427],
 [-95.0454, 16.60191],
 [-94.95721, 16.58712],
 [-94.90289, 16.57086],
 [-94.81347, 16.57683],
 [-94.80446, 16.57232],
 [-94.79141, 16.56986],
 [-94.70969, 16.57245],
 [-94.66778, 16.55954],
 [-94.64046, 16.55142],
 [-94.61736, 16.55408],
 [-94.58549, 16.5548],
 [-94.55626, 16.54069],
 [-94.46931, 16.49708],
 [-94.442, 16.50221],
 [-94.43461, 16.50042],
 [-94.42369, 16.49346],
 [-94.3775, 16.49062],
 [-94.36713, 16.48753],
 [-94.36012, 16.48085],
 [-94.3517, 16.4759],
 [-94.31336, 16.46911],
 [-94.27531, 16.45231],
 [-94.26381, 16.44163],
 [-94.25807, 16.43213],
 [-94.24249, 16.43098],
 [-94.22173, 16.40947],
 [-94.19469, 16.38451],
 [-94.18108, 16.37348],
 [-94.19088, 16.34867],
 [-94.19243, 16.30885],
 [-94.14821, 16.27457],
 [-94.08747, 16.2417],
 [-94.07586, 16.22984],
 [-94.06407, 16.22515],
 [-94.05408, 16.21873],
 [-94.03602, 16.21456],
 [-94.0251, 16.21525],
 [-94.01432, 16.22268],
 [-94.00694, 16.23594],
 [-93.99228, 16.2369],
 [-93.98617, 16.23732],
 [-93.97972, 16.22705],
 [-93.94785, 16.22185],
 [-93.92976, 16.22304],
 [-93.91146, 16.20714],
 [-93.90217, 16.20725],
 [-93.88117, 16.21494],
 [-93.86775, 16.21934],
 [-93.86303, 16.21599],
 [-93.85103, 16.20292],
 [-93.84969, 16.19393],
 [-93.83704, 16.18411],
 [-93.82697, 16.16379],
 [-93.80775, 16.14268],
 [-93.79611, 16.12714],
 [-93.77669, 16.10071],
 [-93.76882, 16.08084],
 [-93.74827, 16.06356],
 [-93.73851, 16.05753],
 [-93.73602, 16.04886],
 [-93.73619, 16.04013],
 [-93.75048, 16.02558],
 [-93.76188, 16.01337],
 [-93.76733, 15.99763],
 [-93.77676, 15.97007],
 [-93.79389, 15.94594],
 [-93.80517, 15.93952],
 [-93.80913, 15.93366],
 [-93.80913, 15.93366],
 [-93.77921, 15.96648],
 [-93.76104, 16.01467],
 [-93.73514, 16.0421],
 [-93.73636, 16.05684],
 [-93.68974, 16.0289],
 [-93.65741, 15.99809],
 [-93.62194, 15.96521],
 [-93.57483, 15.93468],
 [-93.50855, 15.89657],
 [-93.4428, 15.85268],
 [-93.42332, 15.82233],
 [-93.40285, 15.80577],
 [-93.35693, 15.77718],
 [-93.25673, 15.72429],
 [-93.20989, 15.69746],
 [-93.19946, 15.6847],
 [-93.17629, 15.67108],
 [-93.11075, 15.62252],
 [-93.05869, 15.58728],
 [-92.99609, 15.54083],
 [-92.95314, 15.50914],
 [-92.91982, 15.47599],
 [-92.88797, 15.4562],
 [-92.87616, 15.45224],
 [-92.87213, 15.43903],
 [-92.8309, 15.41101],
 [-92.82407, 15.40492],
 [-92.83181, 15.38474],
 [-92.83932, 15.34749],
 [-92.84721, 15.33496],
 [-92.84615, 15.31987],
 [-92.83172, 15.3139],
 [-92.81507, 15.31575],
 [-92.78893, 15.30139],
 [-92.77342, 15.28018],
 [-92.76449, 15.26835],
 [-92.76181, 15.25535],
 [-92.74438, 15.2549],
 [-92.73459, 15.24356],
 [-92.71048, 15.21706],
 [-92.69504, 15.20861],
 [-92.67323, 15.19811],
 [-92.65911, 15.1844],
 [-92.65868, 15.17028],
 [-92.64009, 15.17282],
 [-92.62816, 15.16948],
 [-92.62176, 15.1754],
 [-92.60912, 15.19579],
 [-92.59062, 15.20994],
 [-92.58116, 15.21732],
 [-92.57667, 15.21857],
 [-92.56053, 15.20766],
 [-92.55137, 15.18524],
 [-92.53924, 15.16841],
 [-92.52441, 15.15988],
 [-92.48726, 15.16133],
 [-92.46526, 15.161],
 [-92.45256, 15.1524],
 [-92.44876, 15.1368],
 [-92.43341, 15.11105],
 [-92.39791, 15.03898],
 [-92.40296, 14.98373],
 [-92.39347, 14.95835],
 [-92.36892, 14.92865],
 [-92.31237, 14.90433],
 [-92.2765, 14.90986],
 [-92.26837, 14.92023],
 [-92.2523, 14.91098],
 [-92.24021, 14.89483],
 [-92.23364, 14.88992],
 [-92.21034, 14.90414],
 [-92.19213, 14.91486],
 [-92.19193, 14.88264],
 [-92.19731, 14.84673],
 [-92.19894, 14.82589],
 [-92.19087, 14.8118],
 [-92.17804, 14.76837],
 [-92.16895, 14.73987],
 [-92.15445, 14.70336],
 [-92.11909, 14.69759],
 [-92.09838, 14.70938],
 [-92.07612, 14.71462],
 [-92.03705, 14.72519],
 [-92.01581, 14.7213],
 [-92.01192, 14.7066],
 [-91.97312, 14.69625],
 [-91.90554, 14.70238],
 [-91.86042, 14.6925],
 [-91.84209, 14.69957],
 [-91.82621, 14.69336],
 [-91.79107, 14.70079],
 [-91.76983, 14.70555],
 [-91.75962, 14.70503],
 [-91.74641, 14.71018],
 [-91.73811, 14.70873],
 [-91.72842, 14.7146],
 [-91.71426, 14.72035],
 [-91.70309, 14.73011],
 [-91.68941, 14.73562],
 [-91.68382, 14.74097],
 [-91.68033, 14.75115],
 [-91.67577, 14.76095],
 [-91.67235, 14.77301],
 [-91.67394, 14.79853],
 [-91.66339, 14.80388],
 [-91.66266, 14.81077],
 [-91.64254, 14.82962],
 [-91.63183, 14.83481],
 [-91.62455, 14.85398],
 [-91.61393, 14.86277],
 [-91.60049, 14.85945],
 [-91.58414, 14.86146],
 [-91.5697, 14.86684],
 [-91.54601, 14.85974],
 [-91.52091, 14.85911],
 [-91.49421, 14.86645],
 [-91.4707, 14.86472],
 [-91.47235, 14.87311],
 [-91.46453, 14.89163],
 [-91.45461, 14.8932],
 [-91.44375, 14.88974],
 [-91.437, 14.89748],
 [-91.43145, 14.89658],
 [-91.42497, 14.8968],
 [-91.42431, 14.89107],
 [-91.42236, 14.88699],
 [-91.41449, 14.88856],
 [-91.40732, 14.87885],
 [-91.40382, 14.87505],
 [-91.40713, 14.86737],
 [-91.40658, 14.85232],
 [-91.40158, 14.84001],
 [-91.39025, 14.8399],
 [-91.37635, 14.8425],
 [-91.36679, 14.84385],
 [-91.36324, 14.83619],
 [-91.36011, 14.82821],
 [-91.35463, 14.82722],
 [-91.35086, 14.82189],
 [-91.34511, 14.82468],
 [-91.33458, 14.81942],
 [-91.32743, 14.83009],
 [-91.33024, 14.83454],
 [-91.32012, 14.83589],
 [-91.31165, 14.83419],
 [-91.3107, 14.82315],
 [-91.30773, 14.81089],
 [-91.30655, 14.80223],
 [-91.30653, 14.79762],
 [-91.29338, 14.78984],
 [-91.2916, 14.79727],
 [-91.27486, 14.79539],
 [-91.26207, 14.79298],
 [-91.25438, 14.79165],
 [-91.2521, 14.7988],
 [-91.23159, 14.80728],
 [-91.22116, 14.80608],
 [-91.21263, 14.8088],
 [-91.20268, 14.81438],
 [-91.1891, 14.82991],
 [-91.17925, 14.83733],
 [-91.16341, 14.85076],
 [-91.15098, 14.85436],
 [-91.14365, 14.84878],
 [-91.13198, 14.84948],
 [-91.13782, 14.84113],
 [-91.11699, 14.83776],
 [-91.09677, 14.83365],
 [-91.08808, 14.82624],
 [-91.07429, 14.83527],
 [-91.06664, 14.85169],
 [-91.05823, 14.85997],
 [-91.05041, 14.85486],
 [-91.04797, 14.84496],
 [-91.0427, 14.84127],
 [-91.04121, 14.83346],
 [-91.03381, 14.82441],
 [-91.02289, 14.82484],
 [-91.00743, 14.82517],
 [-90.99928, 14.81764],
 [-90.99335, 14.80887],
 [-90.98996, 14.80181],
 [-90.9788, 14.79842],
 [-90.97685, 14.79333],
 [-90.98306, 14.78697],
 [-90.98739, 14.77801],
 [-90.98385, 14.7627],
 [-90.97629, 14.75273],
 [-90.97194, 14.74664],
 [-90.96354, 14.72836],
 [-90.95321, 14.70821],
 [-90.9495, 14.66307],
 [-90.92737, 14.63945],
 [-90.91359, 14.63203],
 [-90.90508, 14.63219],
 [-90.90045, 14.63798],
 [-90.88775, 14.63735],
 [-90.86317, 14.64957],
 [-90.85125, 14.64556],
 [-90.82815, 14.643],
 [-90.803, 14.62533],
 [-90.79321, 14.62952],
 [-90.79322, 14.63744],
 [-90.78076, 14.64053],
 [-90.75192, 14.63554],
 [-90.74356, 14.63768],
 [-90.74252, 14.64629],
 [-90.74218, 14.65475],
 [-90.7333, 14.65282],
 [-90.72581, 14.65443],
 [-90.72121, 14.65663],
 [-90.70506, 14.65586],
 [-90.69993, 14.63831],
 [-90.69486, 14.63328],
 [-90.68339, 14.62383],
 [-90.68073, 14.61037],
 [-90.65967, 14.60843],
 [-90.65195, 14.61163],
 [-90.65029, 14.60693],
 [-90.63397, 14.60101],
 [-90.62976, 14.60755],
 [-90.62349, 14.60767],
 [-90.62229, 14.6139],
 [-90.61714, 14.61727],
 [-90.60965, 14.61645],
 [-90.60705, 14.62582],
 [-90.6012, 14.6315],
 [-90.56773, 14.63156],
 [-90.55482, 14.63019],
 [-90.53733, 14.64569],
 [-90.52548, 14.64575],
 [-90.52197, 14.64024],
 [-90.50689, 14.6349],
 [-90.50689, 14.6349],
 [-90.50931, 14.61721],
 [-90.50315, 14.60926],
 [-90.48362, 14.58008],
 [-90.48658, 14.5723],
 [-90.47777, 14.57917],
 [-90.4714, 14.57449],
 [-90.4636, 14.56269],
 [-90.45513, 14.55151],
 [-90.46061, 14.5336],
 [-90.48646, 14.48033],
 [-90.47943, 14.45558],
 [-90.47882, 14.42362],
 [-90.47957, 14.41077],
 [-90.46974, 14.39996],
 [-90.45767, 14.35369],
 [-90.43995, 14.33772],
 [-90.41753, 14.33072],
 [-90.40843, 14.30593],
 [-90.39586, 14.31373],
 [-90.3865, 14.30659],
 [-90.36319, 14.29858],
 [-90.34893, 14.28924],
 [-90.33613, 14.29637],
 [-90.32375, 14.28754],
 [-90.30985, 14.27548],
 [-90.30712, 14.26807],
 [-90.27687, 14.25117],
 [-90.24417, 14.25544],
 [-90.20899, 14.25593],
 [-90.19102, 14.23559],
 [-90.17132, 14.22537],
 [-90.16317, 14.21237],
 [-90.15313, 14.21148],
 [-90.11484, 14.1932],
 [-90.01804, 14.1395],
 [-89.96548, 14.107],
 [-89.95599, 14.07415],
 [-89.91648, 14.04177],
 [-89.90879, 14.02549],
 [-89.90826, 14.01794],
 [-89.90076, 14.02693],
 [-89.87774, 14.00537],
 [-89.85582, 13.94575],
 [-89.84935, 13.93659],
 [-89.83296, 13.9542],
 [-89.8153, 13.95018],
 [-89.76588, 13.96231],
 [-89.75427, 13.9686],
 [-89.74014, 13.9715],
 [-89.71379, 13.97265],
 [-89.69706, 13.97337],
 [-89.69003, 13.98783],
 [-89.60963, 13.9879],
 [-89.59689, 13.9846],
 [-89.57453, 13.95589],
 [-89.52173, 13.92904],
 [-89.5046, 13.89715],
 [-89.49623, 13.88378],
 [-89.48592, 13.86275],
 [-89.47054, 13.8528],
 [-89.45488, 13.84045],
 [-89.45166, 13.83072],
 [-89.4408, 13.81666],
 [-89.42506, 13.81383],
 [-89.39849, 13.80398],
 [-89.39146, 13.79749],
 [-89.37186, 13.77962],
 [-89.29899, 13.81866],
 [-89.26529, 13.82712],
 [-89.24228, 13.81273],
 [-89.21935, 13.78839],
 [-89.2009, 13.78234],
 [-89.17884, 13.78557],
 [-89.16651, 13.78536],
 [-89.15917, 13.76647],
 [-89.15935, 13.74105],
 [-89.15851, 13.72141],
 [-89.15074, 13.71515],
 [-89.11818, 13.7112],
 [-89.10165, 13.71222],
 [-89.091, 13.72511],
 [-89.06434, 13.73359],
 [-89.03226, 13.74658],
 [-89.00278, 13.74298],
 [-88.98337, 13.73953],
 [-88.97355, 13.73092],
 [-88.94992, 13.7297],
 [-88.91551, 13.72296],
 [-88.88, 13.72822],
 [-88.86695, 13.72643],
 [-88.85809, 13.71382],
 [-88.84876, 13.68117],
 [-88.83143, 13.66986],
 [-88.79568, 13.6696],
 [-88.76951, 13.68343],
 [-88.74407, 13.67832],
 [-88.71617, 13.66273],
 [-88.69155, 13.65283],
 [-88.68328, 13.63748],
 [-88.66115, 13.61437],
 [-88.65881, 13.60731],
 [-88.61599, 13.60387],
 [-88.61072, 13.60328],
 [-88.5964, 13.59961],
 [-88.56528, 13.61062],
 [-88.54663, 13.60936],
 [-88.52912, 13.5919],
 [-88.519, 13.5827],
 [-88.51173, 13.57351],
 [-88.48728, 13.56768],
 [-88.4479, 13.55612],
 [-88.43413, 13.55802],
 [-88.42966, 13.55768],
 [-88.42332, 13.55642],
 [-88.3593, 13.5497],
 [-88.33766, 13.54597],
 [-88.29401, 13.52599],
 [-88.27902, 13.5212],
 [-88.26674, 13.51728],
 [-88.2028, 13.49648],
 [-88.19001, 13.4898],
 [-88.15153, 13.50999],
 [-88.14122, 13.52781],
 [-88.11839, 13.5459],
 [-88.11178, 13.5569],
 [-88.08172, 13.5813],
 [-88.04476, 13.6028],
 [-88.01127, 13.61814],
 [-87.9858, 13.61973],
 [-87.97163, 13.61569],
 [-87.96321, 13.61513],
 [-87.94545, 13.61075],
 [-87.91062, 13.60699],
 [-87.89307, 13.62784],
 [-87.87893, 13.62819],
 [-87.86412, 13.62091],
 [-87.81893, 13.58003],
 [-87.77695, 13.59078],
 [-87.76171, 13.59474],
 [-87.72409, 13.59028],
 [-87.69787, 13.59282],
 [-87.68178, 13.57885],
 [-87.67017, 13.56244],
 [-87.6511, 13.55692],
 [-87.62875, 13.54518],
 [-87.59773, 13.53853],
 [-87.53011, 13.5327],
 [-87.49494, 13.5356],
 [-87.47857, 13.54395],
 [-87.4621, 13.53437],
 [-87.43856, 13.5269],
 [-87.44798, 13.46947],
 [-87.45159, 13.43911],
 [-87.4467, 13.42972],
 [-87.43117, 13.43036],
 [-87.4039, 13.42494],
 [-87.38554, 13.41788],
 [-87.31982, 13.40496],
 [-87.29993, 13.37322],
 [-87.27052, 13.35541],
 [-87.21595, 13.32413],
 [-87.1859, 13.30964],
 [-87.17884, 13.30468],
 [-87.1785, 13.27407],
 [-87.16199, 13.23174],
 [-87.15347, 13.19566],
 [-87.13381, 13.17132],
 [-87.12833, 13.1371],
 [-87.13412, 13.12925],
 [-87.12308, 13.12052],
 [-87.08242, 13.10136],
 [-87.06853, 13.10279],
 [-87.03642, 13.09264],
 [-86.95893, 13.06294],
 [-86.92324, 13.05662],
 [-86.90015, 13.03821],
 [-86.89215, 13.02754],
 [-86.87243, 12.98403],
 [-86.84949, 12.95932],
 [-86.84606, 12.93718],
 [-86.85513, 12.8604],
 [-86.87521, 12.85007],
 [-86.89883, 12.81994],
 [-86.91142, 12.79384],
 [-86.93768, 12.78656],
 [-86.90402, 12.76508],
 [-86.88028, 12.72857],
 [-86.8722, 12.70821],
 [-86.85771, 12.69413],
 [-86.84357, 12.68644],
 [-86.81405, 12.67906],
 [-86.78027, 12.6596],
 [-86.74529, 12.65045],
 [-86.72397, 12.63465],
 [-86.67044, 12.60556],
 [-86.6726, 12.59145],
 [-86.66382, 12.58969],
 [-86.64355, 12.58646],
 [-86.62543, 12.58009],
 [-86.62439, 12.5712],
 [-86.61054, 12.53989],
 [-86.60387, 12.50646],
 [-86.61723, 12.46282],
 [-86.64912, 12.41016],
 [-86.66201, 12.38303],
 [-86.66424, 12.36078],
 [-86.65441, 12.33993],
 [-86.64682, 12.33156],
 [-86.60359, 12.30962],
 [-86.56658, 12.27324],
 [-86.53107, 12.27534],
 [-86.50069, 12.27729],
 [-86.47437, 12.26765],
 [-86.44592, 12.24775],
 [-86.38393, 12.20368],
 [-86.34428, 12.17106],
 [-86.33506, 12.14425],
 [-86.31744, 12.13185],
 [-86.29184, 12.13808],
 [-86.26663, 12.12952],
 [-86.25113, 12.1167],
 [-86.23642, 12.11486],
 [-86.23641, 12.11484],
 [-86.2354, 12.10992],
 [-86.23775, 12.1076],
 [-86.23869, 12.10475],
 [-86.23806, 12.10325],
 [-86.23728, 12.10046],
 [-86.24047, 12.09827],
 [-86.23969, 12.09595],
 [-86.23508, 12.09091],
 [-86.22872, 12.08397],
 [-86.21683, 12.07077],
 [-86.20681, 12.05976],
 [-86.19989, 12.05221],
 [-86.17919, 12.02931],
 [-86.17674, 12.02667],
 [-86.17204, 12.02524],
 [-86.15715, 12.02282],
 [-86.14687, 12.01851],
 [-86.14018, 12.01109],
 [-86.1328, 12.00198],
 [-86.12737, 11.99937],
 [-86.1158, 11.99966],
 [-86.10918, 11.99706],
 [-86.10403, 11.99911],
 [-86.09913, 12.0024],
 [-86.09278, 12.00302],
 [-86.08061, 11.99605],
 [-86.07435, 11.99008],
 [-86.07194, 11.97945],
 [-86.07626, 11.97404],
 [-86.08287, 11.96961],
 [-86.08314, 11.96749],
 [-86.08255, 11.96276],
 [-86.08465, 11.96032],
 [-86.09196, 11.95567],
 [-86.09118, 11.94347],
 [-86.09181, 11.93341],
 [-86.08829, 11.92665],
 [-86.08238, 11.92097],
 [-86.07897, 11.91639],
 [-86.07835, 11.91179],
 [-86.07833, 11.91039],
 [-86.0662, 11.89981],
 [-86.05889, 11.88618],
 [-86.05358, 11.88091],
 [-86.04308, 11.87529],
 [-86.02888, 11.85773],
 [-86.02144, 11.85228],
 [-86.02826, 11.84438],
 [-86.03067, 11.83732],
 [-86.03572, 11.83062],
 [-86.04014, 11.81701],
 [-86.04485, 11.8039],
 [-86.04909, 11.79173],
 [-86.05015, 11.77987],
 [-86.05314, 11.77694],
 [-86.04713, 11.76025],
 [-86.04511, 11.75562],
 [-86.04084, 11.75326],
 [-86.02554, 11.74537],
 [-86.0131, 11.7265],
 [-86.01193, 11.71582],
 [-85.98262, 11.67711],
 [-85.95765, 11.63275],
 [-85.9492, 11.61932],
 [-85.93434, 11.60437],
 [-85.91507, 11.59894],
 [-85.90065, 11.58229],
 [-85.89898, 11.5738],
 [-85.89823, 11.55563],
 [-85.8972, 11.53067],
 [-85.88857, 11.51509],
 [-85.86173, 11.47422],
 [-85.82275, 11.44059],
 [-85.80454, 11.42489],
 [-85.79451, 11.41504],
 [-85.77982, 11.39943],
 [-85.77166, 11.38877],
 [-85.76405, 11.38333],
 [-85.76248, 11.37863],
 [-85.74269, 11.35553],
 [-85.73278, 11.34109],
 [-85.71438, 11.32371],
 [-85.71078, 11.31715],
 [-85.70474, 11.31264],
 [-85.6953, 11.30986],
 [-85.69244, 11.30723],
 [-85.68753, 11.29728],
 [-85.683, 11.29329],
 [-85.67322, 11.28254],
 [-85.66297, 11.27839],
 [-85.65908, 11.27493],
 [-85.65198, 11.27284],
 [-85.64315, 11.26187],
 [-85.63132, 11.25212],
 [-85.62238, 11.24368],
 [-85.61752, 11.23955],
 [-85.61716, 11.23123],
 [-85.61694, 11.224],
 [-85.61406, 11.21919],
 [-85.6109, 11.21195],
 [-85.61757, 11.20296],
 [-85.61981, 11.19895],
 [-85.62094, 11.19695],
 [-85.62312, 11.19148],
 [-85.61962, 11.18662],
 [-85.61595, 11.18291],
 [-85.61571, 11.18166],
 [-85.61513, 11.17699],
 [-85.61702, 11.17111],
 [-85.61578, 11.16629],
 [-85.61361, 11.16321],
 [-85.61446, 11.16027],
 [-85.61982, 11.15385],
 [-85.62052, 11.1442],
 [-85.61623, 11.13462],
 [-85.61291, 11.13116],
 [-85.6117, 11.12637],
 [-85.61126, 11.11793],
 [-85.60904, 11.114],
 [-85.61038, 11.11175],
 [-85.61276, 11.10287],
 [-85.61178, 11.09902],
 [-85.61513, 11.0966],
 [-85.6179, 11.09055],
 [-85.619, 11.0865],
 [-85.6226, 11.08138],
 [-85.62456, 11.08123],
 [-85.62614, 11.08111],
 [-85.62768, 11.081],
 [-85.63079, 11.07861],
 [-85.63087, 11.07792],
 [-85.63068, 11.07675],
 [-85.62932, 11.06528],
 [-85.62676, 11.0556],
 [-85.62768, 11.03775],
 [-85.63748, 11.02368],
 [-85.63786, 11.02148],
 [-85.63834, 11.01715],
 [-85.63745, 11.01412],
 [-85.63438, 11.01269],
 [-85.63327, 11.00076],
 [-85.62891, 10.99407],
 [-85.62853, 10.99055],
 [-85.62383, 10.98548],
 [-85.624, 10.98181],
 [-85.61927, 10.96224],
 [-85.61552, 10.95501],
 [-85.61235, 10.94771],
 [-85.60898, 10.929],
 [-85.60564, 10.92186],
 [-85.60291, 10.91259],
 [-85.60211, 10.88552],
 [-85.59954, 10.88201],
 [-85.58339, 10.87694],
 [-85.57407, 10.87356],
 [-85.57139, 10.86364],
 [-85.57082, 10.85807],
 [-85.57258, 10.84996],
 [-85.56773, 10.84656],
 [-85.56025, 10.83762],
 [-85.55503, 10.82902],
 [-85.54456, 10.81964],
 [-85.54434, 10.81233],
 [-85.5419, 10.80166],
 [-85.52499, 10.76761],
 [-85.51789, 10.75585],
 [-85.51321, 10.7513],
 [-85.51233, 10.74583],
 [-85.50892, 10.71679],
 [-85.50006, 10.70552],
 [-85.49757, 10.69797],
 [-85.49684, 10.69567],
 [-85.49613, 10.6934],
 [-85.49402, 10.68685],
 [-85.48633, 10.67267],
 [-85.48033, 10.66857],
 [-85.47336, 10.66554],
 [-85.46749, 10.6562],
 [-85.46284, 10.64973],
 [-85.45642, 10.64522],
 [-85.45025, 10.63658],
 [-85.4486, 10.6339],
 [-85.44529, 10.62883],
 [-85.44529, 10.62596],
 [-85.4506, 10.62466],
 [-85.45787, 10.62025],
 [-85.47299, 10.61574],
 [-85.48012, 10.61455],
 [-85.48751, 10.61351],
 [-85.50165, 10.60962],
 [-85.52429, 10.5978],
 [-85.55835, 10.58544],
 [-85.5795, 10.57819],
 [-85.58862, 10.56643],
 [-85.59167, 10.55765],
 [-85.59014, 10.54842],
 [-85.58487, 10.53567],
 [-85.58532, 10.52901],
 [-85.57743, 10.5153],
 [-85.57274, 10.49922],
 [-85.56339, 10.47526],
 [-85.55896, 10.46254],
 [-85.55545, 10.45386],
 [-85.5556, 10.44534],
 [-85.55728, 10.43925],
 [-85.55883, 10.43807],
 [-85.56309, 10.43599],
 [-85.56936, 10.42884],
 [-85.57815, 10.41323],
 [-85.58075, 10.41187],
 [-85.58198, 10.41179],
 [-85.58588, 10.4109],
 [-85.58709, 10.40915],
 [-85.58804, 10.404],
 [-85.60515, 10.39869],
 [-85.61334, 10.39433],
 [-85.6164, 10.39213],
 [-85.61974, 10.38829],
 [-85.62094, 10.38106],
 [-85.62196, 10.37775],
 [-85.62464, 10.37492],
 [-85.63183, 10.37118],
 [-85.63896, 10.3697],
 [-85.6444, 10.36785],
 [-85.64751, 10.36331],
 [-85.66397, 10.36388],
 [-85.67056, 10.364],
 [-85.67409, 10.36283],
 [-85.69589, 10.36376],
 [-85.70192, 10.36142],
 [-85.70653, 10.35792],
 [-85.71011, 10.35147],
 [-85.72645, 10.35208],
 [-85.72758, 10.35215],
 [-85.72887, 10.35223],
 [-85.74525, 10.3533],
 [-85.75203, 10.36425],
 [-85.75544, 10.36437],
 [-85.75779, 10.36715],
 [-85.76083, 10.36886],
 [-85.76855, 10.3687],
 [-85.77425, 10.36842],
 [-85.77472, 10.36826],
 [-85.77822, 10.36507],
 [-85.77892, 10.36118],
 [-85.78269, 10.35122],
 [-85.79228, 10.33428],
 [-85.79836, 10.3309],
 [-85.79951, 10.32755],
 [-85.80231, 10.31771],
 [-85.80674, 10.31285],
 [-85.80641, 10.30781],
 [-85.80669, 10.30721],
 [-85.81063, 10.309],
 [-85.81445, 10.30953],
 [-85.81587, 10.31011],
 [-85.8165, 10.31033],
 [-85.8341, 10.30901],
 [-85.84046, 10.30108],
 [-85.84107, 10.29777],
 [-85.8387, 10.29772],
 [-85.83695, 10.29916],
 [-85.83703, 10.29921],
 [-85.83703, 10.29921],
 [-85.83969, 10.303],
 [-85.81653, 10.31034],
 [-85.79698, 10.29159],
 [-85.77777, 10.2784],
 [-85.76611, 10.26569],
 [-85.73618, 10.26724],
 [-85.7195, 10.25407],
 [-85.68783, 10.27642],
 [-85.6662, 10.27029],
 [-85.6032, 10.26989],
 [-85.57124, 10.2683],
 [-85.557, 10.24567],
 [-85.53725, 10.2244],
 [-85.51213, 10.21478],
 [-85.41351, 10.1341],
 [-85.3615, 10.11339],
 [-85.34397, 10.11847],
 [-85.33183, 10.14183],
 [-85.3114, 10.15369],
 [-85.27982, 10.20065],
 [-85.25199, 10.23974],
 [-85.2264, 10.24786],
 [-85.21952, 10.23552],
 [-85.21643, 10.22212],
 [-85.19548, 10.22203],
 [-85.15181, 10.22384],
 [-85.08618, 10.23332],
 [-85.05034, 10.25649],
 [-85.01937, 10.2635],
 [-85.01693, 10.2601],
 [-84.98232, 10.2341],
 [-84.93511, 10.18081],
 [-84.92437, 10.17279],
 [-84.89297, 10.11297],
 [-84.85367, 10.09113],
 [-84.82267, 10.0932],
 [-84.81557, 10.09636],
 [-84.79844, 10.09368],
 [-84.78734, 10.0838],
 [-84.75965, 10.06223],
 [-84.74264, 10.03398],
 [-84.72034, 10.00294],
 [-84.72598, 9.98863],
 [-84.73315, 9.98716],
 [-84.73684, 9.96482],
 [-84.72312, 9.93426],
 [-84.7124, 9.91889],
 [-84.68336, 9.91678],
 [-84.63407, 9.89238],
 [-84.57589, 9.88588],
 [-84.58777, 9.84285],
 [-84.60553, 9.80849],
 [-84.61301, 9.76614],
 [-84.6302, 9.74922],
 [-84.62632, 9.72957],
 [-84.63792, 9.7018],
 [-84.64452, 9.68974],
 [-84.64909, 9.68149],
 [-84.63861, 9.6697],
 [-84.61749, 9.59801],
 [-84.62299, 9.58919],
 [-84.61211, 9.58099],
 [-84.57967, 9.58214],
 [-84.55341, 9.5719],
 [-84.54242, 9.57415],
 [-84.52706, 9.56473],
 [-84.52388, 9.54627],
 [-84.50303, 9.53553],
 [-84.47449, 9.53304],
 [-84.43002, 9.52425],
 [-84.38595, 9.52916],
 [-84.34157, 9.52377],
 [-84.30943, 9.53072],
 [-84.26104, 9.5275],
 [-84.22955, 9.51255],
 [-84.20313, 9.48411],
 [-84.16787, 9.4515],
 [-84.13767, 9.45186],
 [-84.10851, 9.42499],
 [-84.03917, 9.37327],
 [-83.99414, 9.35394],
 [-83.93966, 9.3184],
 [-83.88832, 9.27888],
 [-83.85772, 9.24885],
 [-83.83171, 9.2188],
 [-83.79592, 9.20498],
 [-83.75019, 9.17849],
 [-83.72523, 9.14265],
 [-83.64534, 9.06204],
 [-83.61971, 9.05161],
 [-83.5807, 9.01387],
 [-83.5311, 8.98163],
 [-83.51986, 8.98688],
 [-83.50952, 8.97536],
 [-83.48373, 8.96387],
 [-83.45957, 8.96211],
 [-83.44955, 8.93314],
 [-83.43343, 8.92604],
 [-83.41393, 8.90903],
 [-83.36396, 8.87912],
 [-83.32236, 8.84639],
 [-83.29791, 8.83741],
 [-83.28838, 8.821],
 [-83.24859, 8.79321],
 [-83.2172, 8.76265],
 [-83.18063, 8.74356],
 [-83.14756, 8.70996],
 [-83.10939, 8.69986],
 [-83.09275, 8.68512],
 [-83.0747, 8.6821],
 [-83.05849, 8.66854],
 [-83.02768, 8.66411],
 [-82.94841, 8.64045],
 [-82.92804, 8.62833],
 [-82.85966, 8.55419],
 [-82.83999, 8.53465],
 [-82.79904, 8.48955],
 [-82.75901, 8.4969],
 [-82.72593, 8.52154],
 [-82.69018, 8.52931],
 [-82.6305, 8.5188],
 [-82.54195, 8.47183],
 [-82.44414, 8.42926],
 [-82.42521, 8.44619],
 [-82.40436, 8.43053],
 [-82.37436, 8.42844],
 [-82.34663, 8.40734],
 [-82.31825, 8.39911],
 [-82.2846, 8.40291],
 [-82.23959, 8.40261],
 [-82.20659, 8.39393],
 [-82.18772, 8.37759],
 [-82.14177, 8.35616],
 [-82.08632, 8.28774],
 [-82.05318, 8.27847],
 [-82.01604, 8.2901],
 [-81.9918, 8.27451],
 [-81.97649, 8.26434],
 [-81.96354, 8.27403],
 [-81.94163, 8.28683],
 [-81.92249, 8.29164],
 [-81.85831, 8.27024],
 [-81.8156, 8.24811],
 [-81.77705, 8.23607],
 [-81.73602, 8.21669],
 [-81.69304, 8.22689],
 [-81.65711, 8.23102],
 [-81.63418, 8.21668],
 [-81.62648, 8.20716],
 [-81.61422, 8.22059],
 [-81.59961, 8.21036],
 [-81.56495, 8.20833],
 [-81.51317, 8.20085],
 [-81.49016, 8.20618],
 [-81.48657, 8.21739],
 [-81.47288, 8.20909],
 [-81.45598, 8.21071],
 [-81.42997, 8.21102],
 [-81.40823, 8.22411],
 [-81.38598, 8.21897],
 [-81.34325, 8.21377],
 [-81.30539, 8.20592],
 [-81.2234, 8.19589],
 [-81.19458, 8.20246],
 [-81.14937, 8.19483],
 [-81.07764, 8.16288],
 [-80.98591, 8.12767],
 [-80.96712, 8.10032],
 [-80.94968, 8.09476],
 [-80.93418, 8.07647],
 [-80.91632, 8.07587],
 [-80.8777, 8.08281],
 [-80.84713, 8.08547],
 [-80.81219, 8.09581],
 [-80.74533, 8.1092],
 [-80.69847, 8.12336],
 [-80.67733, 8.15655],
 [-80.6375, 8.19082],
 [-80.5833, 8.23555],
 [-80.53143, 8.25827],
 [-80.51775, 8.31872],
 [-80.531, 8.36165],
 [-80.53174, 8.40124],
 [-80.51708, 8.41381],
 [-80.47747, 8.44124],
 [-80.38883, 8.47186],
 [-80.35525, 8.515],
 [-80.33452, 8.4995],
 [-80.32246, 8.46465],
 [-80.30463, 8.43034],
 [-80.26873, 8.39972],
 [-80.24371, 8.40305],
 [-80.20139, 8.38563],
 [-80.14466, 8.37917],
 [-80.09057, 8.39752],
 [-80.07171, 8.42624],
 [-80.01021, 8.44874],
 [-79.96015, 8.47444],
 [-79.95037, 8.51769],
 [-79.90966, 8.54963],
 [-79.88439, 8.57078],
 [-79.88854, 8.5988],
 [-79.87483, 8.64319],
 [-79.8698, 8.70527],
 [-79.87991, 8.71428],
 [-79.87705, 8.76218],
 [-79.85514, 8.82232],
 [-79.83964, 8.8421],
 [-79.81904, 8.85037],
 [-79.75815, 8.88224],
 [-79.67879, 8.93759],
 [-79.64574, 8.95514],
 [-79.61601, 8.95708],
 [-79.58558, 8.94986],
 [-79.57027, 8.94098],
 [-79.54862, 8.95113],
 [-79.53652, 8.95999],
 [-79.51821, 8.97457],
 [-79.51987, 8.98238],



// Panama City to Colon Panama

[-79.51987, 8.98238],
[-79.51772, 8.98369],
[-79.51861, 8.98514],
[-79.51932, 8.98475],
[-79.52167, 8.98332],
[-79.5224, 8.98285],
[-79.52355, 8.98264],
[-79.52429, 8.98208],
[-79.52589, 8.9836],
[-79.52635, 8.98339],
[-79.52815, 8.98074],
[-79.53133, 8.97869],
[-79.53383, 8.97698],
[-79.53661, 8.97825],
[-79.54082, 8.98078],
[-79.53983, 8.98433],
[-79.54017, 8.9924],
[-79.5416, 8.99626],
[-79.54149, 9.00282],
[-79.54141, 9.00694],
[-79.54333, 9.01288],
[-79.54303, 9.01516],
[-79.54102, 9.01988],
[-79.53841, 9.02999],
[-79.53323, 9.03799],
[-79.53139, 9.04006],
[-79.53048, 9.03878],
[-79.53231, 9.03872],
[-79.54181, 9.04586],
[-79.54669, 9.05184],
[-79.55571, 9.06091],
[-79.56382, 9.06501],
[-79.57219, 9.07029],
[-79.5868, 9.07492],
[-79.59551, 9.08218],
[-79.60007, 9.08721],
[-79.60254, 9.0913],
[-79.60405, 9.10091],
[-79.60575, 9.10619],
[-79.60925, 9.11124],
[-79.61435, 9.11613],
[-79.61649, 9.12017],
[-79.61899, 9.12444],
[-79.62148, 9.12786],
[-79.62262, 9.13378],
[-79.62634, 9.14122],
[-79.63215, 9.14795],
[-79.63585, 9.15297],
[-79.63903, 9.16391],
[-79.63887, 9.17],
[-79.63764, 9.17606],
[-79.63917, 9.18261],
[-79.64266, 9.18675],
[-79.65674, 9.19606],
[-79.6591, 9.20017],
[-79.65993, 9.20808],
[-79.66065, 9.21132],
[-79.66515, 9.21905],
[-79.67659, 9.23708],
[-79.68446, 9.24466],
[-79.69034, 9.25159],
[-79.69301, 9.25733],
[-79.69775, 9.26161],
[-79.70435, 9.26574],
[-79.70704, 9.2699],
[-79.70927, 9.27223],
[-79.71492, 9.27441],
[-79.72086, 9.27471],
[-79.72369, 9.27549],
[-79.72574, 9.27787],
[-79.72765, 9.27993],
[-79.73287, 9.28263],
[-79.73906, 9.28389],
[-79.74901, 9.28295],
[-79.75636, 9.28045],
[-79.77245, 9.28256],
[-79.7767, 9.28405],
[-79.77934, 9.28804],
[-79.78312, 9.29346],
[-79.78843, 9.29776],
[-79.79486, 9.30161],
[-79.79815, 9.30623],
[-79.80143, 9.31175],
[-79.80123, 9.31572],
[-79.79856, 9.32194],
[-79.79963, 9.32446],
[-79.8001, 9.32638],
[-79.80371, 9.33239],
[-79.80452, 9.33856],
[-79.80541, 9.34515],
[-79.80678, 9.34662],
[-79.80708, 9.34763],
[-79.80566, 9.34896],
[-79.80344, 9.35296],
[-79.80293, 9.35403],
[-79.8028, 9.35427],
[-79.80253, 9.35476],
[-79.80226, 9.35526],
[-79.80178, 9.35622],
[-79.80056, 9.35731],
[-79.79826, 9.35734],
[-79.79359, 9.35799],
[-79.793, 9.3598],
[-79.79253, 9.36233],
[-79.79425, 9.36436],
[-79.79318, 9.36684],
[-79.79326, 9.36968],
[-79.79078, 9.37529],
[-79.78817, 9.37499],
[-79.78432, 9.37894],
[-79.78095, 9.38867],
[-79.77781, 9.39237],
[-79.77615, 9.39707],
[-79.77689, 9.39925],
[-79.7767, 9.40142],
[-79.776, 9.40171],
[-79.77591, 9.40441],
[-79.7721, 9.40662],
[-79.77096, 9.40854],
[-79.76896, 9.40866],
[-79.7662, 9.4094],
[-79.76623, 9.4103],
[-79.76317, 9.4167],
[-79.76117, 9.42457],
[-79.75638, 9.43015],
[-79.75568, 9.43118],
[-79.75141, 9.44063],
[-79.74478, 9.44659],
[-79.74246, 9.44961],
[-79.74107, 9.45302],
[-79.73803, 9.45496],
[-79.73735, 9.45636],
[-79.73145, 9.4595],
[-79.7274, 9.46489],
[-79.72437, 9.46719],
[-79.72239, 9.47387],
[-79.72328, 9.47576],
[-79.71936, 9.48062],
[-79.71901, 9.4825],
[-79.71676, 9.48503],
[-79.71559, 9.48563],
[-79.71296, 9.48499],
[-79.71002, 9.48623],
[-79.7092, 9.4902],
[-79.70354, 9.49252],
[-79.69766, 9.4962],
[-79.69521, 9.49672],
[-79.69248, 9.49865],
[-79.69097, 9.50012],
[-79.68869, 9.50043],
[-79.68742, 9.49977],
[-79.68616, 9.49791],
[-79.68272, 9.50045],
[-79.68346, 9.50401],
[-79.68683, 9.50513],
[-79.69056, 9.50832],
[-79.69177, 9.51079],
[-79.69126, 9.51266],
[-79.68916, 9.51331],
[-79.68792, 9.5158],
[-79.68636, 9.51733],
[-79.68644, 9.51977],
[-79.68412, 9.52325],
[-79.68271, 9.52423],
[-79.68209, 9.52653],
[-79.6775, 9.52864],
[-79.67244, 9.52986],
[-79.67103, 9.53071],
[-79.66971, 9.53053],
[-79.66853, 9.53247],
[-79.66936, 9.53592],
[-79.67182, 9.53616],
[-79.67416, 9.53901],
[-79.67476, 9.5411],
[-79.67404, 9.54366],
[-79.67155, 9.54595],
[-79.67118, 9.54806],
[-79.66653, 9.55157],
[-79.66112, 9.55264],
[-79.6596, 9.55263],
[-79.65898, 9.5533],
[-79.65679, 9.55348],
[-79.65283, 9.55288],
[-79.64689, 9.55343],
[-79.64346, 9.55317],
[-79.64203, 9.55235],
[-79.6399, 9.55329],
[-79.63814, 9.55149],
[-79.63488, 9.5509],
[-79.62848, 9.55093],
[-79.62621, 9.55077],
[-79.62262, 9.55178],
[-79.62063, 9.55159],
[-79.61943, 9.55239],
[-79.61719, 9.55445],
[-79.61105, 9.55521],
[-79.60202, 9.5567],
[-79.59696, 9.5583],
[-79.59408, 9.55791],
[-79.5885, 9.56095],
[-79.58416, 9.56252],
[-79.57975, 9.5634],
[-79.57723, 9.56258],
[-79.57674, 9.56056],
[-79.57586, 9.55981],
[-79.5741, 9.55999],
[-79.57236, 9.55963],
[-79.5697, 9.55972],
[-79.56737, 9.56053],
[-79.56687, 9.56198],
[-79.56432, 9.56322],
[-79.56101, 9.56479],
[-79.55976, 9.56407],
[-79.55784, 9.56177],
[-79.5559, 9.56147],
[-79.55168, 9.56261],
[-79.54568, 9.5633],
[-79.54358, 9.56278],
[-79.54307, 9.56455],
[-79.54114, 9.56575],
[-79.53982, 9.56476],
[-79.53803, 9.56493],
[-79.53601, 9.56382],
[-79.53544, 9.56434],
[-79.53469, 9.56552],
[-79.5335, 9.56467],
[-79.53272, 9.56479],
[-79.53261, 9.56647],
[-79.53228, 9.56752],
[-79.53054, 9.56819],
[-79.52938, 9.56949],
[-79.52744, 9.56786],
[-79.5257, 9.56629],
[-79.52401, 9.56592],
[-79.52113, 9.56603],
[-79.51791, 9.56703],
[-79.51346, 9.56699],
[-79.50897, 9.56635],
[-79.50643, 9.56704],
[-79.50453, 9.56578],
[-79.50293, 9.56586],
[-79.50163, 9.56564],
[-79.50035, 9.56356],
[-79.49858, 9.56436],
[-79.49593, 9.56563],
[-79.49479, 9.56509],
[-79.49358, 9.56516],
[-79.49078, 9.56895],
[-79.49012, 9.57057],
[-79.48764, 9.57209],
[-79.48615, 9.57572],
[-79.48497, 9.57616],
[-79.48397, 9.57686],
[-79.48273, 9.5761],
[-79.47911, 9.57717],
[-79.47575, 9.58248],
[-79.47346, 9.58213],
[-79.47112, 9.58068],
[-79.46902, 9.57973],
[-79.46663, 9.57979],
[-79.46116, 9.57598],
[-79.45846, 9.57433],
[-79.45697, 9.57458],
[-79.45492, 9.57409],
[-79.45313, 9.57251],
[-79.44971, 9.57052],
[-79.44679, 9.57119],
[-79.44112, 9.57278],
[-79.43739, 9.5709],
[-79.43639, 9.57068],
[-79.43271, 9.57162],
[-79.43092, 9.57294],
[-79.42568, 9.57164],
[-79.42263, 9.57277],
[-79.42016, 9.57628],
[-79.41879, 9.5766],
[-79.41615, 9.57606],
[-79.41546, 9.57668],
[-79.41452, 9.57752],
[-79.41472, 9.57846],
[-79.41523, 9.5796],
[-79.414, 9.58121],
[-79.41129, 9.58067],
[-79.41052, 9.57851],
[-79.40968, 9.57764],
[-79.40555, 9.57448],
[-79.40315, 9.57314],
[-79.4018, 9.57313],
[-79.39873, 9.57437],
[-79.39565, 9.57389],
[-79.39392, 9.57287],
[-79.39245, 9.57121],
[-79.38981, 9.57067],
[-79.38548, 9.57128],
[-79.38333, 9.57041],
[-79.38143, 9.57063],
[-79.38158, 9.5723],
[-79.38117, 9.57315],
[-79.37739, 9.57467],
[-79.37537, 9.5755],
[-79.37104, 9.57513],
[-79.36736, 9.57355],
[-79.36625, 9.57344],






// Categena to Tierra Del Feugo
  [-75.47945,10.39101],
  [-75.43581,10.34867],
  [-75.41361,10.33633],
  [-75.4039,10.3218],
  [-75.39293,10.30568],
  [-75.36257,10.27341],
  [-75.35116,10.26123],
  [-75.34281,10.24141],
  [-75.29965,10.18474],
  [-75.29446,10.17012],
  [-75.29958,10.1664],
  [-75.29113,10.15528],
  [-75.26496,10.1362],
  [-75.24351,10.13457],
  [-75.24424,10.11498],
  [-75.24979,10.05908],
  [-75.26031,10.04234],
  [-75.26129,10.01476],
  [-75.272,9.97697],
  [-75.2989,9.94692],
  [-75.32554,9.92411],
  [-75.3514,9.91127],
  [-75.38122,9.89661],
  [-75.40341,9.85706],
  [-75.41922,9.83951],
  [-75.43999,9.82113],
  [-75.47033,9.82008],
  [-75.47902,9.79724],
  [-75.50435,9.77336],
  [-75.51713,9.75679],
  [-75.51777,9.73799],
  [-75.49791,9.72003],
  [-75.48312,9.68932],
  [-75.46788,9.65744],
  [-75.45105,9.63289],
  [-75.4213,9.61472],
  [-75.40317,9.5959],
  [-75.41657,9.5605],
  [-75.42492,9.49755],
  [-75.43421,9.46888],
  [-75.44508,9.45283],
  [-75.4395,9.43704],
  [-75.43559,9.39619],
  [-75.43441,9.36236],
  [-75.41278,9.34201],
  [-75.40829,9.33366],
  [-75.41423,9.32653],
  [-75.41291,9.29737],
  [-75.40989,9.27226],
  [-75.41607,9.24081],
  [-75.40112,9.20429],
  [-75.3835,9.1841],
  [-75.39284,9.17655],
  [-75.39264,9.16503],
  [-75.38314,9.15219],
  [-75.38305,9.14351],
  [-75.39431,9.11773],
  [-75.41505,9.06962],
  [-75.4325,9.06225],
  [-75.45138,9.04296],
  [-75.45771,9.00759],
  [-75.45596,8.98512],
  [-75.45319,8.95777],
  [-75.44716,8.94126],
  [-75.44411,8.92559],
  [-75.45326,8.90411],
  [-75.46714,8.85498],
  [-75.49692,8.82347],
  [-75.50861,8.81371],
  [-75.50139,8.79084],
  [-75.48968,8.75134],
  [-75.49599,8.71192],
  [-75.50053,8.69359],
  [-75.49225,8.67748],
  [-75.49471,8.65185],
  [-75.48271,8.62848],
  [-75.48317,8.61139],
  [-75.48771,8.59838],
  [-75.48295,8.57274],
  [-75.49957,8.54883],
  [-75.51305,8.50132],
  [-75.52246,8.4623],
  [-75.53356,8.44142],
  [-75.54732,8.43329],
  [-75.56888,8.42318],
  [-75.57418,8.41271],
  [-75.57045,8.40139],
  [-75.57256,8.37937],
  [-75.55014,8.33876],
  [-75.53712,8.31274],
  [-75.51921,8.29203],
  [-75.50773,8.27761],
  [-75.4886,8.23142],
  [-75.45783,8.18858],
  [-75.41954,8.14532],
  [-75.39471,8.10799],
  [-75.3562,8.06734],
  [-75.32813,8.04103],
  [-75.27232,8.02199],
  [-75.22899,7.99935],
  [-75.20109,7.99533],
  [-75.20242,7.97839],
  [-75.20947,7.94152],
  [-75.20317,7.90315],
  [-75.21614,7.87756],
  [-75.22097,7.87062],
  [-75.21219,7.86],
  [-75.20536,7.82273],
  [-75.20354,7.80358],
  [-75.20941,7.79066],
  [-75.21768,7.77583],
  [-75.22846,7.77096],
  [-75.25253,7.75676],
  [-75.27244,7.73768],
  [-75.27281,7.70543],
  [-75.28177,7.69101],
  [-75.28343,7.66517],
  [-75.31152,7.65248],
  [-75.31991,7.62913],
  [-75.3237,7.61008],
  [-75.34452,7.59354],
  [-75.35777,7.58986],
  [-75.37826,7.59526],
  [-75.39982,7.58271],
  [-75.38237,7.56805],
  [-75.37342,7.53731],
  [-75.35105,7.5299],
  [-75.34152,7.50214],
  [-75.32408,7.4617],
  [-75.31865,7.46349],
  [-75.31675,7.45422],
  [-75.30745,7.44821],
  [-75.30686,7.43727],
  [-75.30495,7.41294],
  [-75.30816,7.39475],
  [-75.33019,7.36196],
  [-75.33787,7.34743],
  [-75.34996,7.3361],
  [-75.38189,7.30187],
  [-75.38958,7.29398],
  [-75.39246,7.28314],
  [-75.38994,7.27061],
  [-75.39416,7.24708],
  [-75.39487,7.24242],
  [-75.40017,7.23938],
  [-75.40696,7.22879],
  [-75.4189,7.21427],
  [-75.42486,7.2137],
  [-75.42662,7.20285],
  [-75.43414,7.19097],
  [-75.43453,7.17991],
  [-75.44631,7.15997],
  [-75.45324,7.15096],
  [-75.45803,7.14136],
  [-75.46642,7.12206],
  [-75.46847,7.10383],
  [-75.4644,7.08718],
  [-75.46898,7.08835],
  [-75.46596,7.08556],
  [-75.45032,7.07376],
  [-75.45344,7.06911],
  [-75.44865,7.06254],
  [-75.44672,7.05598],
  [-75.45479,7.05094],
  [-75.4637,7.03828],
  [-75.45913,7.02334],
  [-75.4464,7.01944],
  [-75.44297,7.01001],
  [-75.43678,6.99989],
  [-75.43491,6.98652],
  [-75.42833,6.97185],
  [-75.42264,6.96993],
  [-75.4259,6.96436],
  [-75.4321,6.96343],
  [-75.42941,6.96088],
  [-75.42908,6.95234],
  [-75.42547,6.95318],
  [-75.42506,6.94567],
  [-75.42573,6.94029],
  [-75.43093,6.92134],
  [-75.44181,6.89471],
  [-75.44171,6.88725],
  [-75.44561,6.87921],
  [-75.44618,6.8673],
  [-75.45459,6.85988],
  [-75.4571,6.84583],
  [-75.46457,6.83392],
  [-75.48937,6.81099],
  [-75.48627,6.79012],
  [-75.48317,6.7759],
  [-75.49008,6.76839],
  [-75.48791,6.74278],
  [-75.48636,6.73478],
  [-75.47859,6.70419],
  [-75.46713,6.69699],
  [-75.46518,6.68264],
  [-75.46571,6.66997],
  [-75.45871,6.65908],
  [-75.44987,6.63083],
  [-75.4362,6.6058],
  [-75.42225,6.59727],
  [-75.40931,6.59495],
  [-75.40447,6.58268],
  [-75.40597,6.57002],
  [-75.40319,6.55382],
  [-75.41073,6.54908],
  [-75.40591,6.54352],
  [-75.3986,6.5334],
  [-75.39464,6.51714],
  [-75.39194,6.49469],
  [-75.37021,6.46793],
  [-75.36463,6.45714],
  [-75.37208,6.4504],
  [-75.36291,6.44846],
  [-75.35792,6.44879],
  [-75.36169,6.44495],
  [-75.36614,6.43773],
  [-75.37304,6.43259],
  [-75.36968,6.42729],
  [-75.37993,6.42399],
  [-75.3932,6.41733],
  [-75.4109,6.41286],
  [-75.43674,6.39712],
  [-75.45696,6.38446],
  [-75.4813,6.38113],
  [-75.50255,6.35326],
  [-75.54419,6.33832],
  [-75.55609,6.31254],
  [-75.58107,6.24574],
  [-75.58121,6.2442],
  [-75.58121,6.2442],
  [-75.58081,6.24571],
  [-75.58139,6.24617],
  [-75.58077,6.24802],
  [-75.57893,6.24687],
  [-75.57977,6.24828],
  [-75.57497,6.25725],
  [-75.57233,6.26025],
  [-75.57499,6.26335],
  [-75.57367,6.27112],
  [-75.5672,6.29564],
  [-75.56384,6.30131],
  [-75.56049,6.30435],
  [-75.55895,6.3107],
  [-75.55582,6.30959],
  [-75.55052,6.31108],
  [-75.54712,6.31622],
  [-75.54679,6.32049],
  [-75.54433,6.32247],
  [-75.54129,6.32317],
  [-75.53576,6.32335],
  [-75.53203,6.31856],
  [-75.53088,6.31722],
  [-75.52983,6.31758],
  [-75.52935,6.32054],
  [-75.52836,6.32312],
  [-75.52518,6.32301],
  [-75.5236,6.32353],
  [-75.52343,6.32466],
  [-75.52376,6.32775],
  [-75.52134,6.32933],
  [-75.51959,6.3293],
  [-75.51849,6.32784],
  [-75.51645,6.32772],
  [-75.51367,6.32802],
  [-75.51201,6.32688],
  [-75.51165,6.32453],
  [-75.50799,6.3222],
  [-75.50784,6.32005],
  [-75.50728,6.3142],
  [-75.50446,6.31099],
  [-75.50348,6.31165],
  [-75.50206,6.31717],
  [-75.49544,6.31435],
  [-75.49271,6.31499],
  [-75.49024,6.31873],
  [-75.48996,6.32496],
  [-75.48896,6.32953],
  [-75.4854,6.32954],
  [-75.48366,6.32836],
  [-75.4806,6.32823],
  [-75.47871,6.32855],
  [-75.47259,6.32503],
  [-75.4646,6.32202],
  [-75.46372,6.32047],
  [-75.46422,6.31762],
  [-75.46047,6.31007],
  [-75.45534,6.3002],
  [-75.45399,6.29636],
  [-75.45162,6.29391],
  [-75.44892,6.28898],
  [-75.44716,6.27963],
  [-75.44652,6.27807],
  [-75.44313,6.27645],
  [-75.44064,6.2745],
  [-75.43751,6.26829],
  [-75.43863,6.26284],
  [-75.43617,6.25883],
  [-75.43259,6.25416],
  [-75.42782,6.25229],
  [-75.4242,6.24919],
  [-75.42047,6.24479],
  [-75.41715,6.2431],
  [-75.41404,6.23814],
  [-75.40921,6.22995],
  [-75.40397,6.22887],
  [-75.39683,6.22777],
  [-75.39449,6.22569],
  [-75.3907,6.22268],
  [-75.3848,6.21422],
  [-75.38238,6.20394],
  [-75.38259,6.19859],
  [-75.3808,6.1893],
  [-75.37532,6.18445],
  [-75.36741,6.17927],
  [-75.36079,6.17841],
  [-75.35575,6.17669],
  [-75.34554,6.17252],
  [-75.33798,6.16814],
  [-75.32934,6.16854],
  [-75.32343,6.1674],
  [-75.32024,6.16574],
  [-75.31672,6.15768],
  [-75.31362,6.15432],
  [-75.31287,6.15028],
  [-75.31022,6.14605],
  [-75.30575,6.14153],
  [-75.30145,6.14123],
  [-75.29859,6.13989],
  [-75.29486,6.13524],
  [-75.28859,6.13399],
  [-75.28432,6.13688],
  [-75.28042,6.13715],
  [-75.2764,6.13506],
  [-75.27228,6.13448],
  [-75.26679,6.13514],
  [-75.26369,6.13488],
  [-75.25936,6.1318],
  [-75.25234,6.12784],
  [-75.24594,6.1252],
  [-75.24512,6.12642],
  [-75.24464,6.1256],
  [-75.24147,6.12531],
  [-75.23934,6.12697],
  [-75.23935,6.1286],
  [-75.23783,6.1298],
  [-75.23724,6.13062],
  [-75.23604,6.13177],
  [-75.23439,6.13151],
  [-75.23091,6.13111],
  [-75.22738,6.12836],
  [-75.22728,6.13031],
  [-75.22626,6.13161],
  [-75.22451,6.13172],
  [-75.22461,6.13258],
  [-75.22304,6.1316],
  [-75.22145,6.13004],
  [-75.21818,6.12929],
  [-75.21809,6.13073],
  [-75.21686,6.13112],
  [-75.21327,6.1315],
  [-75.21068,6.12972],
  [-75.20724,6.12933],
  [-75.2047,6.12836],
  [-75.20344,6.12848],
  [-75.19958,6.125],
  [-75.19758,6.12358],
  [-75.19454,6.12422],
  [-75.19334,6.12239],
  [-75.19231,6.12105],
  [-75.19047,6.12151],
  [-75.18775,6.12121],
  [-75.18702,6.12265],
  [-75.1838,6.12259],
  [-75.1817,6.12494],
  [-75.17983,6.12529],
  [-75.17854,6.12618],
  [-75.17883,6.12883],
  [-75.17793,6.12981],
  [-75.17891,6.13248],
  [-75.18215,6.13456],
  [-75.18188,6.13753],
  [-75.18293,6.14123],
  [-75.18472,6.14247],
  [-75.1873,6.1447],
  [-75.18771,6.14644],
  [-75.185,6.14747],
  [-75.18333,6.14959],
  [-75.18211,6.14894],
  [-75.18262,6.15033],
  [-75.18211,6.15158],
  [-75.18113,6.15083],
  [-75.17806,6.15105],
  [-75.17698,6.15045],
  [-75.17729,6.14968],
  [-75.17535,6.14903],
  [-75.17362,6.15007],
  [-75.17228,6.15041],
  [-75.17058,6.15013],
  [-75.16947,6.14936],
  [-75.16812,6.14989],
  [-75.16606,6.1486],
  [-75.16466,6.15013],
  [-75.1631,6.15001],
  [-75.16274,6.15124],
  [-75.16054,6.1502],
  [-75.15796,6.15127],
  [-75.15601,6.15203],
  [-75.15742,6.15263],
  [-75.15714,6.15413],
  [-75.15699,6.15555],
  [-75.15592,6.15686],
  [-75.15482,6.15659],
  [-75.15345,6.15659],
  [-75.15234,6.15591],
  [-75.15233,6.15487],
  [-75.15125,6.15522],
  [-75.14986,6.15354],
  [-75.14804,6.15402],
  [-75.14666,6.15384],
  [-75.14671,6.15263],
  [-75.14467,6.15237],
  [-75.14395,6.15276],
  [-75.14351,6.15487],
  [-75.14236,6.15443],
  [-75.14097,6.15439],
  [-75.13955,6.15604],
  [-75.13881,6.15991],
  [-75.13849,6.15811],
  [-75.13686,6.15734],
  [-75.13609,6.15586],
  [-75.13472,6.15492],
  [-75.13386,6.15616],
  [-75.13251,6.15562],
  [-75.13134,6.15596],
  [-75.13144,6.15756],
  [-75.13112,6.15931],
  [-75.12985,6.15916],
  [-75.12756,6.1589],
  [-75.12662,6.15717],
  [-75.12501,6.15728],
  [-75.12191,6.15651],
  [-75.12019,6.15434],
  [-75.12025,6.15266],
  [-75.11985,6.14881],
  [-75.1189,6.14707],
  [-75.11754,6.14703],
  [-75.11592,6.14517],
  [-75.11674,6.14414],
  [-75.1146,6.13994],
  [-75.11373,6.1385],
  [-75.11351,6.13772],
  [-75.1129,6.13837],
  [-75.11133,6.13694],
  [-75.11071,6.13803],
  [-75.10944,6.138],
  [-75.10922,6.13712],
  [-75.10933,6.13574],
  [-75.10778,6.13461],
  [-75.10507,6.1333],
  [-75.10393,6.13523],
  [-75.10334,6.13634],
  [-75.10146,6.13687],
  [-75.09954,6.13694],
  [-75.09868,6.13853],
  [-75.0962,6.14048],
  [-75.09454,6.14232],
  [-75.09443,6.14336],
  [-75.09304,6.1439],
  [-75.09288,6.14529],
  [-75.09181,6.14621],
  [-75.09073,6.14759],
  [-75.09185,6.14872],
  [-75.09282,6.14985],
  [-75.09168,6.15078],
  [-75.09084,6.1505],
  [-75.08964,6.15117],
  [-75.08774,6.15233],
  [-75.08613,6.15219],
  [-75.08416,6.15363],
  [-75.08419,6.15493],
  [-75.08336,6.15494],
  [-75.0817,6.15589],
  [-75.08015,6.15649],
  [-75.07953,6.15579],
  [-75.08073,6.15455],
  [-75.07975,6.15219],
  [-75.07825,6.14986],
  [-75.07598,6.14967],
  [-75.07493,6.15046],
  [-75.07285,6.14994],
  [-75.07002,6.15047],
  [-75.06873,6.14984],
  [-75.06825,6.14823],
  [-75.06769,6.14764],
  [-75.06738,6.14857],
  [-75.06598,6.14937],
  [-75.06475,6.14831],
  [-75.06198,6.14912],
  [-75.06118,6.14759],
  [-75.05977,6.14773],
  [-75.05773,6.14668],
  [-75.05602,6.14655],
  [-75.0559,6.14589],
  [-75.05504,6.14684],
  [-75.05356,6.14838],
  [-75.05583,6.14942],
  [-75.05635,6.15069],
  [-75.05491,6.152],
  [-75.05381,6.1541],
  [-75.05281,6.154],
  [-75.05245,6.15563],
  [-75.05225,6.15644],
  [-75.05148,6.15794],
  [-75.05026,6.16086],
  [-75.04833,6.16417],
  [-75.0473,6.16488],
  [-75.04582,6.16486],
  [-75.0439,6.1649],
  [-75.04088,6.16469],
  [-75.04007,6.16321],
  [-75.03927,6.1638],
  [-75.03839,6.16196],
  [-75.03692,6.16157],
  [-75.03597,6.15989],
  [-75.03479,6.16041],
  [-75.03422,6.16211],
  [-75.03141,6.16263],
  [-75.03031,6.16403],
  [-75.0277,6.16415],
  [-75.02526,6.16478],
  [-75.02191,6.16599],
  [-75.021,6.16762],
  [-75.01838,6.16883],
  [-75.01578,6.17131],
  [-75.01364,6.17336],
  [-75.01168,6.17562],
  [-75.00866,6.17648],
  [-75.00584,6.17892],
  [-75.00468,6.18005],
  [-75.00507,6.18075],
  [-75.00484,6.18218],
  [-75.00291,6.18319],
  [-75.00118,6.18361],
  [-74.99522,6.18575],
  [-74.99316,6.18801],

  // San Carlos to VillaVicencio to Cali to Quito









  [-74.99316, 6.18801],
[-75.00493, 6.18241],
[-75.01436, 6.17266],
[-75.02407, 6.16508],
[-75.0348, 6.16046],
[-75.04286, 6.16507],
[-75.05081, 6.15823],
[-75.05378, 6.15413],
[-75.05418, 6.14847],
[-75.05264, 6.13825],
[-75.04699, 6.13784],
[-75.04403, 6.13205],
[-75.04174, 6.1212],
[-75.04178, 6.10779],
[-75.04056, 6.09982],
[-75.03588, 6.09776],
[-75.03506, 6.09275],
[-75.03356, 6.07775],
[-75.01716, 6.06429],
[-75.01026, 6.05283],
[-74.99028, 6.04013],
[-74.98709, 6.0353],
[-74.98039, 6.03039],
[-74.9629, 6.01579],
[-74.95089, 6.00647],
[-74.94226, 5.99964],
[-74.93792, 5.99945],
[-74.93883, 5.99367],
[-74.93295, 5.99139],
[-74.93116, 5.98974],
[-74.92092, 5.98753],
[-74.91774, 5.9825],
[-74.91406, 5.97997],
[-74.91142, 5.97319],
[-74.90582, 5.97882],
[-74.89751, 5.97736],
[-74.87823, 5.96663],
[-74.87205, 5.96044],
[-74.8713, 5.95343],
[-74.86361, 5.95285],
[-74.85755, 5.94242],
[-74.85917, 5.93089],
[-74.86142, 5.92199],
[-74.8599, 5.91308],
[-74.85711, 5.90361],
[-74.84826, 5.90688],
[-74.84574, 5.91283],
[-74.84124, 5.91931],
[-74.83384, 5.92491],
[-74.82045, 5.93337],
[-74.80669, 5.92134],
[-74.7958, 5.91736],
[-74.78318, 5.91718],
[-74.7721, 5.91239],
[-74.77226, 5.9055],
[-74.76763, 5.90073],
[-74.73628, 5.89837],
[-74.70606, 5.8997],
[-74.66722, 5.90431],
[-74.6455, 5.90426],
[-74.63235, 5.90013],
[-74.6175, 5.89321],
[-74.61506, 5.87806],
[-74.60135, 5.8585],
[-74.5898, 5.8556],
[-74.58037, 5.8473],
[-74.57799, 5.83825],
[-74.58206, 5.83323],
[-74.58779, 5.81898],
[-74.60645, 5.77998],
[-74.63146, 5.76346],
[-74.63167, 5.75565],
[-74.62369, 5.73195],
[-74.61111, 5.68863],
[-74.61304, 5.67387],
[-74.62412, 5.5758],
[-74.63056, 5.53212],
[-74.63632, 5.51749],
[-74.63908, 5.51067],
[-74.62691, 5.50308],
[-74.61223, 5.49754],
[-74.60232, 5.48871],
[-74.60354, 5.47987],
[-74.60119, 5.46474],
[-74.59746, 5.44293],
[-74.60141, 5.42333],
[-74.60506, 5.41596],
[-74.6046, 5.39947],
[-74.60401, 5.38362],
[-74.5943, 5.37118],
[-74.58158, 5.3694],
[-74.56647, 5.3655],
[-74.56466, 5.35209],
[-74.56031, 5.34278],
[-74.56495, 5.33425],
[-74.56776, 5.30759],
[-74.5688, 5.29659],
[-74.56846, 5.27927],
[-74.56608, 5.26434],
[-74.57036, 5.24672],
[-74.57999, 5.20956],
[-74.58827, 5.1638],
[-74.59108, 5.1395],
[-74.59065, 5.1182],
[-74.59612, 5.11466],
[-74.59363, 5.10659],
[-74.594, 5.08707],
[-74.60844, 5.07796],
[-74.59153, 5.06258],
[-74.58897, 5.06427],
[-74.58456, 5.06011],
[-74.5803, 5.05701],
[-74.57551, 5.06059],
[-74.56766, 5.07515],
[-74.56056, 5.07057],
[-74.56033, 5.05998],
[-74.55305, 5.03251],
[-74.54342, 5.02239],
[-74.53698, 4.99707],
[-74.5289, 5.01008],
[-74.52728, 5.02131],
[-74.52818, 5.02593],
[-74.52357, 5.02354],
[-74.51737, 5.01748],
[-74.51137, 5.02303],
[-74.51028, 5.02745],
[-74.50707, 5.0267],
[-74.49295, 5.02508],
[-74.48377, 5.01869],
[-74.48078, 5.01278],
[-74.47022, 5.02074],
[-74.46803, 5.0294],
[-74.45862, 5.03579],
[-74.45179, 5.04279],
[-74.4527, 5.05215],
[-74.44629, 5.07371],
[-74.42655, 5.06616],
[-74.41689, 5.06578],
[-74.40089, 5.05802],
[-74.38182, 5.0489],
[-74.37199, 5.03999],
[-74.36213, 5.02487],
[-74.35191, 5.02132],
[-74.34744, 5.01387],
[-74.34116, 4.9999],
[-74.32546, 4.98519],
[-74.32293, 4.9929],
[-74.32181, 4.97885],
[-74.32287, 4.97569],
[-74.31323, 4.97666],
[-74.30996, 4.97573],
[-74.31303, 4.97268],
[-74.30882, 4.96455],
[-74.29659, 4.95203],
[-74.2918, 4.94464],
[-74.29739, 4.94938],
[-74.31126, 4.94396],
[-74.30225, 4.93217],
[-74.29359, 4.92961],
[-74.29125, 4.92217],
[-74.29757, 4.91246],
[-74.30964, 4.90383],
[-74.31265, 4.89602],
[-74.30709, 4.89533],
[-74.3007, 4.88243],
[-74.29844, 4.87399],
[-74.29247, 4.87304],
[-74.289, 4.85751],
[-74.24023, 4.82711],
[-74.22306, 4.81504],
[-74.20936, 4.79961],
[-74.15601, 4.75853],
[-74.12588, 4.727],
[-74.09674, 4.69942],
[-74.09081, 4.6953],
[-74.09411, 4.68867],
[-74.10573, 4.67252],
[-74.13746, 4.63419],
[-74.1454, 4.59099],
[-74.13325, 4.53969],
[-74.1232, 4.52097],
[-74.12212, 4.51242],
[-74.10446, 4.50433],
[-74.10965, 4.49297],
[-74.10404, 4.48624],
[-74.09099, 4.47357],
[-74.07921, 4.45122],
[-74.07542, 4.45119],
[-74.06976, 4.45495],
[-74.06912, 4.45107],
[-74.06817, 4.44375],
[-74.06474, 4.44677],
[-74.06437, 4.45358],
[-74.0602, 4.45237],
[-74.04901, 4.45431],
[-74.04129, 4.44828],
[-74.03901, 4.44179],
[-74.02581, 4.44104],
[-74.01854, 4.44516],
[-74.01595, 4.43999],
[-74.00664, 4.4377],
[-74.01294, 4.43202],
[-74.00306, 4.4288],
[-73.98527, 4.41818],
[-73.97065, 4.41764],
[-73.94561, 4.41313],
[-73.91779, 4.40808],
[-73.91062, 4.40243],
[-73.90897, 4.38958],
[-73.90661, 4.37616],
[-73.90102, 4.3712],
[-73.89495, 4.3593],
[-73.88366, 4.35219],
[-73.87527, 4.33746],
[-73.86623, 4.3157],
[-73.8553, 4.29963],
[-73.8428, 4.28871],
[-73.83638, 4.28421],
[-73.83519, 4.27708],
[-73.82839, 4.26559],
[-73.82706, 4.25573],
[-73.82648, 4.2445],
[-73.8231, 4.2386],
[-73.81865, 4.22695],
[-73.80347, 4.19918],
[-73.79866, 4.20346],
[-73.79059, 4.20266],
[-73.77702, 4.19898],
[-73.76107, 4.19761],
[-73.75357, 4.19515],
[-73.74533, 4.19996],
[-73.73115, 4.20485],
[-73.71546, 4.19099],
[-73.70286, 4.16989],
[-73.70387, 4.16007],
[-73.67063, 4.14602],
[-73.65577, 4.13594],
[-73.64732, 4.12332],
[-73.64108, 4.12223],
[-73.63539, 4.13835],
[-73.63701, 4.14863],
[-73.6377, 4.15138],
[-73.6377, 4.15138],
[-73.64039, 4.1402],
[-73.651, 4.13686],
[-73.65554, 4.13507],
[-73.65824, 4.14098],
[-73.68975, 4.15379],
[-73.70644, 4.16346],
[-73.7031, 4.17424],
[-73.7201, 4.19892],
[-73.73498, 4.20378],
[-73.74957, 4.19549],
[-73.77876, 4.19915],
[-73.79465, 4.20308],
[-73.79954, 4.20013],
[-73.8056, 4.20055],
[-73.81076, 4.21104],
[-73.81711, 4.23084],
[-73.82482, 4.24276],
[-73.82794, 4.25886],
[-73.83491, 4.27341],
[-73.83471, 4.27884],
[-73.84438, 4.29281],
[-73.86721, 4.31799],
[-73.87051, 4.32816],
[-73.87855, 4.34537],
[-73.89642, 4.36101],
[-73.9041, 4.37365],
[-73.91038, 4.39832],
[-73.91615, 4.40749],
[-73.92919, 4.41106],
[-73.95751, 4.41424],
[-73.97247, 4.41979],
[-73.99271, 4.42135],
[-74.01314, 4.43103],
[-74.00604, 4.43628],
[-74.01545, 4.44008],
[-74.0181, 4.44526],
[-74.02546, 4.441],
[-74.04204, 4.44416],
[-74.04487, 4.4515],
[-74.0602, 4.45237],
[-74.06474, 4.44677],
[-74.06975, 4.44974],
[-74.07488, 4.45144],
[-74.09158, 4.47442],
[-74.10837, 4.49372],
[-74.10434, 4.50427],
[-74.11317, 4.50702],
[-74.12419, 4.52259],
[-74.13629, 4.55066],
[-74.1377, 4.56824],
[-74.15648, 4.57891],
[-74.16678, 4.5959],
[-74.20832, 4.58462],
[-74.22533, 4.57318],
[-74.23648, 4.55449],
[-74.25608, 4.54157],
[-74.26485, 4.54023],
[-74.27124, 4.54034],
[-74.27878, 4.53059],
[-74.28923, 4.54602],
[-74.30039, 4.54778],
[-74.30641, 4.54106],
[-74.31011, 4.53302],
[-74.32178, 4.5288],
[-74.34823, 4.52659],
[-74.3574, 4.52392],
[-74.36052, 4.51267],
[-74.36787, 4.49769],
[-74.37732, 4.4772],
[-74.38671, 4.46471],
[-74.379, 4.453],
[-74.38326, 4.439],
[-74.39127, 4.42997],
[-74.38829, 4.42265],
[-74.39475, 4.41314],
[-74.38389, 4.40256],
[-74.39158, 4.39318],
[-74.38522, 4.38237],
[-74.38681, 4.37307],
[-74.38143, 4.36268],
[-74.37605, 4.35448],
[-74.38532, 4.34908],
[-74.41261, 4.33929],
[-74.42233, 4.32138],
[-74.47182, 4.28549],
[-74.50974, 4.2656],
[-74.53463, 4.26026],
[-74.52758, 4.25714],
[-74.5291, 4.25519],
[-74.54884, 4.25961],
[-74.56608, 4.27228],
[-74.58363, 4.26697],
[-74.59569, 4.25606],
[-74.60183, 4.25758],
[-74.61771, 4.25101],
[-74.61927, 4.23632],
[-74.62607, 4.22513],
[-74.64293, 4.21274],
[-74.65394, 4.20744],
[-74.65829, 4.2169],
[-74.67752, 4.21221],
[-74.68949, 4.21764],
[-74.71329, 4.23603],
[-74.7208, 4.23824],
[-74.7241, 4.24717],
[-74.73962, 4.24601],
[-74.75274, 4.25099],
[-74.77928, 4.25457],
[-74.82717, 4.25506],
[-74.85691, 4.25343],
[-74.88885, 4.24307],
[-74.91568, 4.23247],
[-74.94063, 4.22111],
[-74.95953, 4.21507],
[-74.97569, 4.223],
[-74.98382, 4.2488],
[-74.99782, 4.26495],
[-75.01482, 4.27999],
[-75.03983, 4.29296],
[-75.07363, 4.32505],
[-75.08824, 4.32329],
[-75.09106, 4.32766],
[-75.10756, 4.34971],
[-75.13917, 4.36866],
[-75.15475, 4.38646],
[-75.18808, 4.41444],
[-75.19161, 4.42273],
[-75.20374, 4.42319],
[-75.22228, 4.42497],
[-75.25211, 4.41843],
[-75.27449, 4.40747],
[-75.29755, 4.40067],
[-75.2972, 4.40563],
[-75.30311, 4.4083],
[-75.31314, 4.41363],
[-75.3169, 4.41734],
[-75.32598, 4.41388],
[-75.33251, 4.40708],
[-75.34132, 4.41102],
[-75.35494, 4.41188],
[-75.36054, 4.41539],
[-75.36822, 4.41908],
[-75.38598, 4.42342],
[-75.3984, 4.43454],
[-75.40613, 4.44171],
[-75.42913, 4.44172],
[-75.45846, 4.43282],
[-75.4732, 4.43798],
[-75.49594, 4.43794],
[-75.5046, 4.43724],
[-75.51162, 4.44162],
[-75.52791, 4.45153],
[-75.53653, 4.45895],
[-75.5423, 4.46932],
[-75.55116, 4.46928],
[-75.56745, 4.48102],
[-75.56549, 4.49031],
[-75.56875, 4.50225],
[-75.58527, 4.51864],
[-75.59603, 4.5295],
[-75.60293, 4.52432],
[-75.60485, 4.5211],
[-75.60832, 4.51711],
[-75.60744, 4.5231],
[-75.61436, 4.52939],
[-75.61997, 4.52562],
[-75.63447, 4.52665],
[-75.64011, 4.5235],
[-75.65199, 4.51616],
[-75.65721, 4.50503],
[-75.67122, 4.50062],
[-75.69163, 4.49101],
[-75.69961, 4.48689],
[-75.70314, 4.48941],
[-75.70951, 4.4757],
[-75.7301, 4.46538],
[-75.76314, 4.47123],
[-75.77375, 4.46805],
[-75.80897, 4.4478],
[-75.83122, 4.44565],
[-75.8574, 4.4354],
[-75.86766, 4.41601],
[-75.87517, 4.40931],
[-75.8847, 4.40928],
[-75.90784, 4.40681],
[-75.92965, 4.39289],
[-75.94196, 4.38544],
[-75.95128, 4.37102],
[-75.98421, 4.36079],
[-76.02931, 4.35247],
[-76.05101, 4.34743],
[-76.06532, 4.32942],
[-76.08301, 4.29432],
[-76.10024, 4.2741],
[-76.1326, 4.23357],
[-76.14927, 4.21711],
[-76.15372, 4.20147],
[-76.16618, 4.19165],
[-76.17565, 4.16546],
[-76.18207, 4.12971],
[-76.1755, 4.10261],
[-76.17226, 4.08526],
[-76.18273, 4.06364],
[-76.20271, 4.05358],
[-76.22253, 4.01109],
[-76.24064, 3.98278],
[-76.27004, 3.9562],
[-76.28693, 3.92574],
[-76.31234, 3.90177],
[-76.30887, 3.87636],
[-76.29767, 3.85062],
[-76.30117, 3.83375],
[-76.30995, 3.80138],
[-76.32583, 3.77811],
[-76.32568, 3.7527],
[-76.3195, 3.71185],
[-76.32188, 3.69979],
[-76.32979, 3.68108],
[-76.34536, 3.67461],
[-76.36703, 3.66672],
[-76.38525, 3.63852],
[-76.39988, 3.61295],
[-76.41576, 3.55054],
[-76.42798, 3.52417],
[-76.4345, 3.50478],
[-76.45758, 3.4976],
[-76.49458, 3.48752],
[-76.51831, 3.46239],
[-76.5209, 3.45545],
[-76.53198, 3.45164],
[-76.53198, 3.45164],
[-76.54373, 3.39403],
[-76.52458, 3.3897],
[-76.52529, 3.37186],
[-76.52238, 3.32358],
[-76.53288, 3.26927],
[-76.52592, 3.24718],
[-76.49882, 3.21364],
[-76.47259, 3.17681],
[-76.46087, 3.15948],
[-76.46594, 3.11903],
[-76.47473, 3.05731],
[-76.49602, 3.02841],
[-76.49725, 2.99091],
[-76.51932, 2.97584],
[-76.5292, 2.94201],
[-76.54705, 2.90798],
[-76.55024, 2.87373],
[-76.54437, 2.84406],
[-76.53708, 2.82798],
[-76.54275, 2.81682],
[-76.55196, 2.7894],
[-76.55091, 2.75025],
[-76.543, 2.71894],
[-76.5314, 2.67619],
[-76.53714, 2.64286],
[-76.52262, 2.62516],
[-76.5145, 2.61485],
[-76.5282, 2.60939],
[-76.53263, 2.58922],
[-76.56106, 2.5699],
[-76.56611, 2.55613],
[-76.55806, 2.5432],
[-76.54709, 2.51763],
[-76.54897, 2.50721],
[-76.55997, 2.49448],
[-76.61413, 2.47743],
[-76.6395, 2.45904],
[-76.62104, 2.43364],
[-76.659, 2.39063],
[-76.69181, 2.34521],
[-76.68935, 2.31964],
[-76.68574, 2.3098],
[-76.69269, 2.30941],
[-76.70302, 2.30558],
[-76.70635, 2.29545],
[-76.71008, 2.29187],
[-76.70428, 2.28416],
[-76.70926, 2.27727],
[-76.72637, 2.27943],
[-76.72588, 2.27304],
[-76.73833, 2.26214],
[-76.76012, 2.2543],
[-76.76804, 2.24368],
[-76.77446, 2.24275],
[-76.79505, 2.22923],
[-76.79635, 2.21511],
[-76.80521, 2.20627],
[-76.82904, 2.2001],
[-76.88075, 2.17826],
[-76.90851, 2.16533],
[-76.94002, 2.13758],
[-76.96512, 2.13475],
[-76.98415, 2.1168],
[-76.99814, 2.09223],
[-77.01101, 2.09408],
[-77.04265, 2.07322],
[-77.05739, 2.06287],
[-77.07079, 2.03849],
[-77.09984, 1.98878],
[-77.1426, 1.94204],
[-77.15408, 1.9454],
[-77.15683, 1.93422],
[-77.17044, 1.92295],
[-77.19239, 1.89075],
[-77.20515, 1.87591],
[-77.22678, 1.8701],
[-77.26694, 1.835],
[-77.27646, 1.81794],
[-77.27189, 1.80475],
[-77.27108, 1.79424],
[-77.28375, 1.76882],
[-77.29619, 1.75146],
[-77.31567, 1.70399],
[-77.32704, 1.68374],
[-77.31795, 1.6779],
[-77.32724, 1.67607],
[-77.33308, 1.66082],
[-77.33185, 1.65258],
[-77.34146, 1.65333],
[-77.33664, 1.64557],
[-77.33782, 1.62819],
[-77.35387, 1.59859],
[-77.35627, 1.57688],
[-77.34831, 1.57176],
[-77.35461, 1.56321],
[-77.36313, 1.54755],
[-77.36513, 1.53296],
[-77.35, 1.5391],
[-77.34216, 1.53126],
[-77.32229, 1.52858],
[-77.32626, 1.52285],
[-77.32329, 1.50656],
[-77.31308, 1.51622],
[-77.31589, 1.50432],
[-77.29022, 1.48771],
[-77.28028, 1.478],
[-77.2771, 1.46018],
[-77.28661, 1.40037],
[-77.28478, 1.35764],
[-77.2765, 1.3378],
[-77.2791, 1.32507],
[-77.26939, 1.29477],
[-77.26649, 1.27536],
[-77.26071, 1.26753],
[-77.26306, 1.22555],
[-77.24041, 1.21987],
[-77.24052, 1.20068],
[-77.24335, 1.18839],
[-77.25365, 1.1689],
[-77.28635, 1.16336],
[-77.32097, 1.14306],
[-77.33871, 1.14856],
[-77.36075, 1.13085],
[-77.3871, 1.10837],
[-77.37903, 1.1082],
[-77.37954, 1.10287],
[-77.38901, 1.09658],
[-77.41416, 1.08484],
[-77.42065, 1.06504],
[-77.42584, 1.07546],
[-77.42731, 1.06021],
[-77.44608, 1.05536],
[-77.45652, 1.04712],
[-77.46591, 1.03059],
[-77.4775, 1.02219],
[-77.47121, 0.98463],
[-77.47065, 0.95207],
[-77.48035, 0.93561],
[-77.49678, 0.93048],
[-77.53264, 0.90076],
[-77.5814, 0.85986],
[-77.59722, 0.83599],
[-77.62125, 0.82237],
[-77.66117, 0.81526],
[-77.67831, 0.82108],
[-77.69842, 0.81664],
[-77.73862, 0.78957],
[-77.73572, 0.77097],
[-77.72823, 0.76118],
[-77.71636, 0.74234],
[-77.72959, 0.73161],
[-77.71202, 0.7141],
[-77.70704, 0.69041],
[-77.7264, 0.63798],
[-77.74716, 0.61661],
[-77.77679, 0.60512],
[-77.81559, 0.60341],
[-77.82913, 0.59375],
[-77.82691, 0.57195],
[-77.84737, 0.54072],
[-77.8669, 0.51282],
[-77.90979, 0.5137],
[-77.92503, 0.50447],
[-77.93398, 0.48663],
[-77.94357, 0.48953],
[-77.94341, 0.47726],
[-77.94818, 0.48046],
[-77.9428, 0.47151],
[-77.93998, 0.4558],
[-77.94486, 0.45503],
[-77.94661, 0.445],
[-77.96378, 0.43552],
[-77.9923, 0.43677],
[-78.02357, 0.46922],
[-78.0865, 0.47617],
[-78.11089, 0.49416],
[-78.11835, 0.47129],
[-78.12367, 0.41941],
[-78.13167, 0.39682],
[-78.12352, 0.40716],
[-78.12503, 0.40192],
[-78.11237, 0.40136],
[-78.11215, 0.37473],
[-78.11628, 0.35971],
[-78.12847, 0.35339],
[-78.14422, 0.34154],
[-78.16314, 0.33847],
[-78.17151, 0.34261],
[-78.19994, 0.33463],
[-78.22229, 0.31786],
[-78.23615, 0.29036],
[-78.24663, 0.25742],
[-78.26366, 0.23438],
[-78.27048, 0.22129],
[-78.25889, 0.21081],
[-78.2229, 0.19112],
[-78.20636, 0.17128],
[-78.19938, 0.17417],
[-78.20209, 0.16273],
[-78.18691, 0.14792],
[-78.18521, 0.1146],
[-78.19755, 0.06762],
[-78.20482, 0.046],
[-78.21918, 0.04523],
[-78.24786, 0.03914],
[-78.27093, 0.02793],
[-78.2786, 0.02563],
[-78.28186, 0.00831],
[-78.28852, 0.01285],
[-78.29806, 0.0061],
[-78.30871, -0.00228],
[-78.33307, -0.02281],
[-78.33353, -0.03951],
[-78.35796, -0.05802],
[-78.37461, -0.06175],
[-78.37293, -0.06471],
[-78.39202, -0.08805],
[-78.40283, -0.0914],
[-78.41292, -0.10045],
[-78.41176, -0.09135],
[-78.41973, -0.10217],
[-78.45438, -0.10861],
[-78.45427, -0.11577],
[-78.44462, -0.14069],
[-78.46766, -0.16313],
[-78.4679, -0.18064],









  // Quito Ecuador to Lima Peru
  [-78.46599,-0.1649],
  [-78.45501,-0.17442],
  [-78.46283,-0.19483],
  [-78.46912,-0.22532],
  [-78.47345,-0.23831],
  [-78.48757,-0.23667],
  [-78.50263,-0.25863],
  [-78.52146,-0.31886],
  [-78.56658,-0.47259],
  [-78.60053,-0.59018],
  [-78.5864,-0.66371],
  [-78.61592,-0.74895],
  [-78.61968,-0.85173],
  [-78.65997,-0.91953],
  [-78.63814,-0.9776],
  [-78.60233,-1.03354],
  [-78.59,-1.11827],
  [-78.58265,-1.23333],
  [-78.63213,-1.3006],
  [-78.65476,-1.41747],
  [-78.72519,-1.53101],
  [-78.71741,-1.5711],
  [-78.69917,-1.59693],
  [-78.68063,-1.63001],
  [-78.68781,-1.64778],
  [-78.7058,-1.65043],
  [-78.75269,-1.65073],
  [-78.76048,-1.68289],
  [-78.77245,-1.72779],
  [-78.77942,-1.74928],
  [-78.80654,-1.7577],
  [-78.84058,-1.75517],
  [-78.86021,-1.78129],
  [-78.89664,-1.85714],
  [-78.91737,-1.91715],
  [-78.92177,-1.94334],
  [-78.93279,-1.93797],
  [-78.97539,-1.95249],
  [-78.96448,-1.99102],
  [-78.98578,-2.08859],
  [-79.00191,-2.09143],
  [-79.01972,-2.10527],
  [-79.06289,-2.16042],
  [-79.15138,-2.21987],
  [-79.20555,-2.25104],
  [-79.23211,-2.30152],
  [-79.34252,-2.33339],
  [-79.39457,-2.33617],
  [-79.47728,-2.31009],
  [-79.54933,-2.26145],
  [-79.62792,-2.2537],
  [-79.63085,-2.38714],
  [-79.62677,-2.438],
  [-79.58411,-2.50946],
  [-79.54606,-2.54629],
  [-79.61596,-2.60563],
  [-79.62186,-2.67562],
  [-79.62749,-2.68439],
  [-79.67198,-2.71754],
  [-79.67729,-2.75154],
  [-79.70693,-2.81896],
  [-79.71368,-2.93307],
  [-79.76486,-3.10167],
  [-79.78242,-3.17822],
  [-79.80975,-3.21546],
  [-79.83975,-3.24274],
  [-79.84326,-3.24598],
  [-79.89306,-3.34632],
  [-79.95683,-3.42757],
  [-79.97711,-3.46009],
  [-80.00041,-3.52754],
  [-80.02606,-3.54224],
  [-80.04331,-3.57057],
  [-80.06047,-3.57483],
  [-80.07023,-3.61348],
  [-80.09947,-3.64301],
  [-80.07945,-3.68729],
  [-80.04416,-3.73432],
  [-80.03776,-3.80261],
  [-80.0197,-3.80536],
  [-80.05004,-3.86736],
  [-80.02303,-3.90235],
  [-80.02831,-3.95141],
  [-80.05116,-4.02211],
  [-80.04221,-4.03205],
  [-80.04521,-4.04732],
  [-80.07054,-4.0598],
  [-80.11052,-4.11822],
  [-80.12278,-4.16485],
  [-80.11555,-4.19544],
  [-80.18068,-4.22061],
  [-80.19944,-4.24711],
  [-80.21146,-4.30489],
  [-80.24243,-4.37633],
  [-80.30089,-4.42327],
  [-80.33794,-4.42946],
  [-80.38595,-4.46948],
  [-80.43717,-4.50746],
  [-80.47184,-4.52502],
  [-80.4745,-4.5455],
  [-80.53139,-4.6045],
  [-80.53575,-4.64978],
  [-80.56771,-4.70275],
  [-80.64779,-4.83394],
  [-80.66816,-4.84635],
  [-80.6976,-4.89684],
  [-80.69049,-5.16985],
  [-80.68312,-5.20723],
  [-80.63827,-5.23884],
  [-80.61244,-5.26844],
  [-80.61817,-5.29796],
  [-80.61685,-5.36162],
  [-80.61125,-5.42045],
  [-80.58966,-5.52931],
  [-80.56058,-5.71588],
  [-80.50652,-6.07125],
  [-80.48212,-6.14022],
  [-80.4078,-6.21862],
  [-80.26259,-6.30057],
  [-80.15889,-6.43312],
  [-79.99647,-6.56427],
  [-79.95256,-6.63397],
  [-79.90441,-6.68299],
  [-79.8907,-6.72656],
  [-79.86467,-6.76371],
  [-79.86244,-6.79638],
  [-79.83499,-6.80058],
  [-79.8294,-6.83014],
  [-79.81731,-6.87135],
  [-79.75703,-6.91811],
  [-79.63799,-6.98226],
  [-79.57818,-7.04908],
  [-79.45998,-7.14271],
  [-79.4296,-7.19364],
  [-79.46151,-7.24034],
  [-79.49126,-7.25745],
  [-79.47445,-7.29755],
  [-79.46527,-7.36278],
  [-79.50167,-7.40993],
  [-79.50137,-7.44618],
  [-79.43308,-7.46921],
  [-79.3698,-7.57549],
  [-79.3202,-7.69014],
  [-79.26093,-7.76742],
  [-79.20407,-7.79785],
  [-79.1308,-7.87872],
  [-79.09631,-7.94195],
  [-79.07051,-8.00808],
  [-79.09689,-8.0876],
  [-79.06285,-8.13441],
  [-79.04065,-8.15081],
  [-79.02753,-8.1383],
  [-79.01217,-8.15973],
  [-78.97813,-8.18902],
  [-78.91827,-8.28395],
  [-78.83093,-8.40464],
  [-78.70195,-8.48061],
  [-78.67473,-8.5492],
  [-78.66924,-8.57778],
  [-78.63744,-8.63052],
  [-78.62148,-8.66365],
  [-78.63744,-8.69507],
  [-78.65431,-8.83516],
  [-78.62282,-8.96506],
  [-78.61712,-9.01886],
  [-78.59068,-9.05647],
  [-78.53197,-9.12776],
  [-78.44982,-9.22175],
  [-78.41547,-9.28987],
  [-78.36714,-9.41986],
  [-78.30835,-9.47912],
  [-78.27738,-9.49664],
  [-78.27375,-9.54053],
  [-78.2549,-9.57985],
  [-78.2692,-9.66351],
  [-78.26608,-9.72888],
  [-78.2411,-9.76351],
  [-78.1794,-9.80633],
  [-78.22014,-9.9178],
  [-78.11751,-10.13851],
  [-78.0371,-10.24196],
  [-78.04759,-10.33326],
  [-77.99062,-10.39156],
  [-77.94071,-10.42234],
  [-77.92414,-10.45017],
  [-77.88331,-10.55436],
  [-77.85529,-10.64282],
  [-77.80777,-10.66391],
  [-77.76521,-10.71807],
  [-77.71955,-10.77824],
  [-77.69345,-10.84962],
  [-77.65617,-10.92082],
  [-77.61939,-11.00153],
  [-77.58252,-11.07852],
  [-77.59684,-11.13559],
  [-77.5161,-11.26816],
  [-77.47003,-11.33181],
  [-77.43742,-11.3769],
  [-77.39242,-11.39865],
  [-77.33924,-11.44991],
  [-77.26793,-11.55753],
  [-77.2307,-11.61327],
  [-77.19514,-11.60431],
  [-77.2071,-11.61534],
  [-77.20067,-11.65349],
  [-77.17935,-11.69897],
  [-77.16067,-11.73098],
  [-77.13617,-11.73319],
  [-77.1592,-11.77171],
  [-77.15165,-11.78775],
  [-77.13642,-11.80292],
  [-77.06926,-11.87424],
  [-77.06448,-11.98795],
  [-77.0409,-12.05043],
  [-77.04264,-12.04636],
  [-77.04264,-12.04636],
  [-77.01394,-12.03964],
  [-77.00622,-12.03258],
  [-76.98726,-12.04155],
  [-76.9725,-12.06922],
  [-76.97945,-12.10216],
  [-76.98023,-12.16761],
  [-76.97594,-12.21162],
  [-76.96737,-12.22998],
  [-76.9212,-12.25646],
  [-76.86377,-12.29249],
  [-76.81172,-12.34527],
  [-76.7566,-12.40933],
  [-76.74557,-12.48679],
  [-76.69597,-12.57052],
  [-76.66238,-12.59758],
  [-76.66958,-12.63215],
  [-76.63686,-12.70594],
  [-76.58895,-12.77475],
  [-76.53915,-12.81741],
  [-76.5159,-12.84777],
  [-76.51097,-12.88233],
  [-76.49628,-12.94019],
  [-76.48908,-12.99081],
  [-76.45536,-13.06096],
  [-76.39939,-13.1183],
  [-76.34647,-13.19583],
  [-76.31774,-13.22462],
  [-76.28747,-13.2415],
  [-76.25945,-13.29494],
  [-76.18668,-13.42196],
  [-76.18046,-13.56167],
  [-76.16695,-13.60671],
  [-76.17364,-13.64819],
  [-76.1674,-13.75278],
  [-76.15581,-13.80163],
  [-76.12252,-13.8787],
  [-76.05143,-13.90502],
  [-75.9932,-13.92319],
  [-75.88364,-13.93373],
  [-75.79964,-13.94937],
  [-75.77458,-13.97983],
  [-75.76216,-14.02039],
  [-75.74943,-14.04661],
  [-75.73699,-14.08036],
  [-75.71658,-14.09983],
  [-75.71719,-14.13953],
  [-75.71432,-14.18716],
  [-75.71262,-14.21063],
  [-75.68406,-14.27062],
  [-75.67785,-14.30482],
  [-75.65541,-14.34083],
  [-75.6035,-14.38839],
  [-75.44188,-14.45493],
  [-75.23764,-14.51312],
  [-75.22145,-14.51843],
  [-75.20611,-14.52836],
  [-75.19621,-14.52922],
  [-75.18186,-14.54121],
  [-75.20843,-14.56495],
  [-75.18756,-14.63041],
  [-75.16157,-14.66882],
  [-75.10416,-14.70337],
  [-75.02661,-14.7841],
  [-74.97209,-14.81941],
  [-74.94076,-14.8422],
  [-74.91475,-14.83123],
  [-74.85994,-14.82626],
  [-74.82008,-14.82397],
  [-74.79656,-14.85069],
  [-74.78432,-14.86472],
  [-74.77807,-14.86993],
  [-74.7565,-14.85911],
  [-74.73576,-14.84907],
  [-74.71172,-14.83568],
  [-74.66416,-14.81267],
  [-74.62892,-14.77681],
  [-74.55486,-14.72753],
  [-74.53057,-14.70498],
  [-74.45971,-14.66901],
  [-74.36222,-14.6554],
  [-74.31766,-14.6614],
  [-74.31045,-14.64921],
  [-74.29946,-14.64021],
  [-74.28585,-14.64176],
  [-74.29257,-14.62168],
  [-74.28645,-14.61497],
  [-74.27283,-14.61845],
  [-74.28317,-14.60084],
  [-74.26491,-14.61249],
  [-74.2427,-14.61793],
  [-74.23009,-14.62902],
  [-74.22198,-14.62346],
  [-74.2241,-14.63579],
  [-74.21407,-14.63996],
  [-74.20844,-14.63039],
  [-74.1881,-14.64996],
  [-74.17429,-14.67191],
  [-74.15918,-14.69385],
  [-74.13778,-14.6916],
  [-74.1411,-14.69865],
  [-74.1309,-14.69692],
  [-74.10919,-14.67479],
  [-74.10806,-14.66805],
  [-74.08911,-14.67776],
  [-74.08225,-14.68178],
  [-74.06988,-14.69005],
  [-74.06104,-14.6814],
  [-74.05274,-14.67773],
  [-74.05597,-14.65886],
  [-74.04294,-14.63987],
  [-74.03832,-14.62776],
  [-74.02763,-14.62776],
  [-74.00793,-14.61536],
  [-73.99403,-14.60853],
  [-73.99205,-14.62049],
  [-73.97834,-14.64008],
  [-73.91659,-14.61606],
  [-73.85853,-14.6269],
  [-73.83238,-14.63906],
  [-73.82309,-14.63307],
  [-73.80549,-14.6289],
  [-73.80395,-14.63744],
  [-73.7856,-14.63971],
  [-73.76394,-14.65055],
  [-73.75446,-14.64021],
  [-73.74538,-14.63443],
  [-73.71338,-14.63778],
  [-73.64122,-14.60907],
  [-73.58366,-14.59903],
  [-73.58168,-14.58671],
  [-73.56273,-14.56931],
  [-73.54832,-14.55692],
  [-73.51548,-14.54991],
  [-73.41988,-14.56759],
  [-73.39537,-14.56647],
  [-73.36894,-14.54851],
  [-73.34034,-14.53966],
  [-73.29135,-14.53027],
  [-73.26782,-14.50594],
  [-73.26437,-14.51098],
  [-73.24069,-14.47578],
  [-73.18572,-14.39661],
  [-73.1666,-14.36392],
  [-73.16227,-14.34539],
  [-73.18234,-14.32912],
  [-73.21813,-14.30785],
  [-73.27634,-14.27784],
  [-73.31583,-14.23537],
  [-73.32717,-14.20645],
  [-73.32445,-14.16465],
  [-73.29325,-14.12878],
  [-73.26895,-14.08161],
  [-73.23649,-14.05241],
  [-73.2092,-14.02138],
  [-73.1798,-14.00046],
  [-73.15706,-13.97612],
  [-73.15431,-13.95962],
  [-73.13973,-13.94937],
  [-73.10142,-13.94056],
  [-73.06371,-13.92226],
  [-73.02731,-13.88165],
  [-73.01218,-13.86003],
  [-72.99374,-13.86352],
  [-72.96875,-13.83189],
  [-72.94818,-13.78888],
  [-72.9234,-13.7427],
  [-72.91684,-13.70624],
  [-72.92487,-13.66748],
  [-72.93354,-13.65657],
  [-72.9085,-13.65534],
  [-72.90759,-13.64369],
  [-72.87191,-13.62346],
  [-72.86247,-13.61032],
  [-72.85809,-13.61357],
  [-72.85158,-13.6162],
  [-72.85166,-13.60821],
  [-72.84613,-13.59953],
  [-72.84774,-13.59324],
  [-72.8476,-13.5954],
  [-72.84255,-13.59312],
  [-72.83718,-13.57595],
  [-72.82534,-13.58073],
  [-72.81947,-13.59027],
  [-72.81933,-13.57963],
  [-72.82047,-13.55623],
  [-72.80541,-13.54299],
  [-72.75889,-13.5349],
  [-72.75846,-13.54023],
  [-72.74977,-13.54327],
  [-72.76047,-13.55142],
  [-72.75506,-13.56268],
  [-72.6908,-13.54087],
  [-72.67458,-13.54762],
  [-72.66348,-13.54661],
  [-72.66314,-13.54045],
  [-72.64826,-13.54243],
  [-72.61195,-13.55073],
  [-72.58745,-13.56255],
  [-72.56037,-13.55116],
  [-72.52155,-13.53963],
  [-72.50741,-13.51034],
  [-72.47441,-13.49992],
  [-72.43661,-13.46776],
  [-72.41117,-13.45383],
  [-72.40173,-13.44673],
  [-72.39742,-13.44762],
  [-72.39551,-13.45814],
  [-72.37354,-13.44651],
  [-72.34246,-13.44335],
  [-72.32089,-13.44703],
  [-72.27736,-13.47512],
  [-72.21974,-13.49653],
  [-72.19134,-13.49907],
  [-72.16745,-13.48579],
  [-72.14479,-13.464],
  [-72.12654,-13.47611],
  [-72.08794,-13.47978],
  [-72.03049,-13.50326],
  [-72.00465,-13.50809],
  [-71.99316,-13.51626],
  [-71.99608,-13.52354],
  [-71.96745,-13.53202],
  [-71.96745,-13.53202],
  [-71.93143,-13.54289],
  [-71.90876,-13.54141],
  [-71.89371,-13.54849],
  [-71.86831,-13.55462],
  [-71.83457,-13.56632],
  [-71.79619,-13.5895],
  [-71.73205,-13.6105],
  [-71.72755,-13.60646],
  [-71.72502,-13.61229],
  [-71.70875,-13.62072],
  [-71.69579,-13.63655],
  [-71.68104,-13.6643],
  [-71.65584,-13.68222],
  [-71.64512,-13.68897],
  [-71.63703,-13.68961],
  [-71.63132,-13.68555],
  [-71.62063,-13.68423],
  [-71.60731,-13.68834],
  [-71.59446,-13.68902],
  [-71.59702,-13.71593],
  [-71.60015,-13.7339],
  [-71.59205,-13.7458],
  [-71.57422,-13.77146],
  [-71.56514,-13.78642],
  [-71.55263,-13.79809],
  [-71.54393,-13.80712],
  [-71.5507,-13.81765],
  [-71.5418,-13.82136],
  [-71.53636,-13.83537],
  [-71.53663,-13.84453],
  [-71.52873,-13.85402],
  [-71.51134,-13.87406],
  [-71.50488,-13.89338],
  [-71.49866,-13.90782],
  [-71.50155,-13.91575],
  [-71.50575,-13.92495],
  [-71.50059,-13.94061],
  [-71.49473,-13.96782],
  [-71.48731,-13.98203],
  [-71.47232,-13.99512],
  [-71.46185,-13.99472],
  [-71.45735,-14.01533],
  [-71.44977,-14.0381],
  [-71.44452,-14.06357],
  [-71.43892,-14.08793],
  [-71.43726,-14.097],
  [-71.43057,-14.09837],
  [-71.41431,-14.12145],
  [-71.3966,-14.14517],
  [-71.37157,-14.17744],
  [-71.3634,-14.18373],
  [-71.34745,-14.18577],
  [-71.31479,-14.19948],
  [-71.28929,-14.2203],
  [-71.25403,-14.24533],
  [-71.23138,-14.25241],
  [-71.22994,-14.259],
  [-71.237,-14.2725],
  [-71.23252,-14.28099],
  [-71.21811,-14.29337],
  [-71.19877,-14.32407],
  [-71.13863,-14.38507],
  [-71.13262,-14.40489],
  [-71.12495,-14.40898],
  [-71.11781,-14.41245],
  [-71.11038,-14.43035],
  [-71.08676,-14.44351],
  [-71.06916,-14.45707],
  [-71.04231,-14.46936],
  [-71.03129,-14.47036],
  [-71.0188,-14.47847],
  [-70.99042,-14.48189],
  [-70.95937,-14.4957],
  [-70.94368,-14.49588],
  [-70.92792,-14.50517],
  [-70.90274,-14.52254],
  [-70.88307,-14.53838],
  [-70.87457,-14.56729],
  [-70.86491,-14.58601],
  [-70.85192,-14.5925],
  [-70.83439,-14.60042],
  [-70.81004,-14.60419],
  [-70.77336,-14.61422],
  [-70.75061,-14.6401],
  [-70.74434,-14.67311],
  [-70.75079,-14.73195],
  [-70.73359,-14.76043],
  [-70.722,-14.78212],
  [-70.72646,-14.78742],
  [-70.71994,-14.79534],
  [-70.67104,-14.82836],
  [-70.61977,-14.86495],
  [-70.60387,-14.87628],
  [-70.59957,-14.88645],
  [-70.58704,-14.89622],
  [-70.57877,-14.90931],
  [-70.51949,-14.94982],
  [-70.49815,-14.96596],
  [-70.45864,-14.9743],
  [-70.41569,-14.98278],
  [-70.4055,-14.9873],
  [-70.39397,-15.00256],
  [-70.37771,-15.02391],
  [-70.3657,-15.04404],
  [-70.3548,-15.06936],
  [-70.35401,-15.08933],
  [-70.36282,-15.12123],
  [-70.35842,-15.16677],
  [-70.35544,-15.18374],
  [-70.35189,-15.20839],
  [-70.34347,-15.22086],
  [-70.31899,-15.24711],
  [-70.30139,-15.2698],
  [-70.2829,-15.28042],
  [-70.26196,-15.2802],
  [-70.24885,-15.28222],
  [-70.24215,-15.2921],
  [-70.22903,-15.31237],
  [-70.2024,-15.31578],
  [-70.18051,-15.34617],
  [-70.16152,-15.38272],
  [-70.14032,-15.4411],
  [-70.1384,-15.46669],
  [-70.13619,-15.48062],
  [-70.13007,-15.4906],
  [-70.1288,-15.50232],
  [-70.12196,-15.51886],
  [-70.11142,-15.54341],
  [-70.08874,-15.62332],
  [-70.05631,-15.73873],
  [-70.04711,-15.77097],
  [-70.03405,-15.77684],
  [-70.03347,-15.79012],
  [-70.03759,-15.80432],
  [-70.03614,-15.81542],
  [-70.0296,-15.81833],
  [-70.03412,-15.82748],
  [-70.03187,-15.83555],
  [-70.03442,-15.84101],
  [-70.022,-15.84911],
  [-70.01378,-15.85598],
  [-69.99567,-15.86483],
  [-69.96869,-15.85331],
  [-69.95359,-15.862],
  [-69.93494,-15.86898],
  [-69.9175,-15.88215],
  [-69.90135,-15.8863],
  [-69.89424,-15.88384],
  [-69.88868,-15.88991],
  [-69.88804,-15.9024],
  [-69.88065,-15.92202],
  [-69.85949,-15.94158],
  [-69.83193,-15.94697],
  [-69.78807,-15.98195],
  [-69.72944,-16.03067],
  [-69.70185,-16.0538],
  [-69.65997,-16.07724],
  [-69.64158,-16.09108],
  [-69.62666,-16.09333],
  [-69.60097,-16.11124],
  [-69.58095,-16.13087],
  [-69.56893,-16.14461],
  [-69.54278,-16.16295],
  [-69.51537,-16.20739],
  [-69.48945,-16.21849],
  [-69.4599,-16.22164],
  [-69.4573,-16.21664],
  [-69.4497,-16.21489],
  [-69.41708,-16.20243],
  [-69.40756,-16.2061],
  [-69.38651,-16.22514],
  [-69.36906,-16.2443],
  [-69.33946,-16.25542],
  [-69.30182,-16.25532],
  [-69.29608,-16.25958],
  [-69.29083,-16.27039],
  [-69.26835,-16.3093],
  [-69.19162,-16.40793],
  [-69.15905,-16.43631],
  [-69.15486,-16.44865],
  [-69.13706,-16.46137],
  [-69.09576,-16.50378],
  [-69.06306,-16.53724],
  [-69.03982,-16.55706],
  [-69.03188,-16.56505],
  [-69.02503,-16.56221],
  [-69.02797,-16.55371],
  [-69.01753,-16.55928],
  [-68.97805,-16.57738],
  [-68.9307,-16.59697],
  [-68.8912,-16.59947],
  [-68.8411,-16.59953],
  [-68.78319,-16.58054],
  [-68.71637,-16.56538],
  [-68.68185,-16.57164],
  [-68.64802,-16.56674],
  [-68.62293,-16.5636],
  [-68.59572,-16.58003],
  [-68.57338,-16.59406],
  [-68.54823,-16.5762],
  [-68.52814,-16.58155],
  [-68.51494,-16.58298],
  [-68.48067,-16.56353],
  [-68.41137,-16.54064],
  [-68.39953,-16.53349],
  [-68.37249,-16.53119],
  [-68.31013,-16.52691],
  [-68.23026,-16.49818],
  [-68.21787,-16.50025],
  [-68.20565,-16.5092],
  [-68.192,-16.49908],
  [-68.18001,-16.49862],
  [-68.16929,-16.50294],
  [-68.16262,-16.50442],
  [-68.16315,-16.49814],
  [-68.1683,-16.48744],
  [-68.15935,-16.48412],
  [-68.15997,-16.47218],
  [-68.1504,-16.46177],
  [-68.14725,-16.46184],
  [-68.15271,-16.46885],
  [-68.14873,-16.48063],
  [-68.14008,-16.48855],
  [-68.12268,-16.49554],
  [-68.11926,-16.48997],
  [-68.11926,-16.48997],
  [-68.139,-16.49122],
  [-68.15506,-16.46605],
  [-68.18199,-16.62634],
  [-68.18108,-16.78828],
  [-68.11126,-16.92783],
  [-68.01799,-17.07937],
  [-67.96652,-17.12101],
  [-67.94561,-17.16736],
  [-67.92712,-17.215],
  [-67.86658,-17.26568],
  [-67.66993,-17.37682],
  [-67.53599,-17.42581],
  [-67.44078,-17.49957],
  [-67.31095,-17.58136],
  [-67.2068,-17.65907],
  [-67.11307,-17.92705],
  [-67.09263,-17.96335],
  [-67.05429,-17.97884],
  [-67.02225,-18.02903],
  [-67.01045,-18.08934],
  [-66.98641,-18.15616],
  [-67.01617,-18.17995],
  [-66.97778,-18.39713],
  [-66.93215,-18.58285],
  [-66.86961,-18.73218],
  [-66.77795,-18.91567],
  [-66.77541,-19.15743],
  [-66.87198,-19.3779],
  [-66.90573,-19.42947],
  [-66.8914,-19.47476],
  [-66.79566,-19.6343],
  [-66.78472,-19.70848],
  [-66.80846,-19.80174],
  [-66.86237,-19.8779],
  [-66.86962,-20.0308],
  [-66.91848,-20.11398],
  [-66.95112,-20.17474],
  [-66.95578,-20.24215],
  [-66.89544,-20.36755],
  [-66.82475,-20.46521],
  [-66.81806,-20.47417],
  [-66.79668,-20.52419],
  [-66.76451,-20.571],
  [-66.63728,-20.68136],
  [-66.61127,-20.7382],
  [-66.55464,-20.82313],
  [-66.53075,-20.86724],
  [-66.48195,-20.88677],
  [-66.4501,-20.84636],
  [-66.39098,-20.8185],
  [-66.31661,-20.86879],
  [-66.2739,-20.90047],
  [-66.24767,-20.91457],
  [-66.24754,-20.92637],
  [-66.2205,-20.93195],
  [-66.18249,-20.95444],
  [-66.10012,-21.02379],
  [-66.04578,-21.10513],
  [-65.98298,-21.11201],
  [-65.93198,-21.17263],
  [-65.91417,-21.1879],
  [-65.89333,-21.17414],
  [-65.86282,-21.2063],
  [-65.80186,-21.23285],
  [-65.76788,-21.24967],
  [-65.74861,-21.33253],
  [-65.73088,-21.39971],
  [-65.71438,-21.43243],
  [-65.69398,-21.52456],
  [-65.66179,-21.52328],
  [-65.62105,-21.5672],
  [-65.59439,-21.57746],
  [-65.58671,-21.61367],
  [-65.57625,-21.68681],
  [-65.56237,-21.7655],
  [-65.51396,-21.89261],
  [-65.59615,-22.08946],
  [-65.60504,-22.14084],
  [-65.67048,-22.25373],
  [-65.69105,-22.35748],
  [-65.70801,-22.46767],
  [-65.69552,-22.53905],
  [-65.69609,-22.6974],
  [-65.69675,-22.81338],
  [-65.66,-22.86077],
  [-65.60984,-22.89711],
  [-65.58899,-22.92114],
  [-65.54953,-22.9233],
  [-65.48427,-22.97149],
  [-65.46175,-22.97312],
  [-65.40728,-22.9496],
  [-65.3682,-23.01437],
  [-65.38784,-23.07025],
  [-65.37069,-23.11561],
  [-65.35913,-23.19571],
  [-65.35039,-23.31629],
  [-65.34963,-23.44045],
  [-65.40752,-23.61314],
  [-65.47901,-23.77178],
  [-65.46573,-23.95946],
  [-65.4247,-24.04842],
  [-65.3935,-24.14192],
  [-65.31506,-24.19822],
  [-65.14794,-24.32311],
  [-65.03853,-24.47311],
  [-65.07992,-24.54341],
  [-65.05357,-24.65657],
  [-65.0288,-24.72828],
  [-65.0191,-24.80491],
  [-65.00434,-24.87747],
  [-64.9813,-24.89648],
  [-64.99348,-24.99694],
  [-65.01136,-25.10062],
  [-65.00791,-25.14785],
  [-64.97631,-25.17188],
  [-64.94307,-25.21133],
  [-64.92145,-25.26173],
  [-64.9264,-25.30429],
  [-64.93591,-25.33471],
  [-64.91572,-25.35429],
  [-64.83321,-25.36043],
  [-64.76375,-25.3841],
  [-64.67362,-25.38576],
  [-64.59665,-25.39331],
  [-64.51572,-25.3676],
  [-64.39956,-25.29442],
  [-64.39297,-25.24714],
  [-64.37277,-25.20837],
  [-64.27724,-25.10917],
  [-64.20098,-25.09776],
  [-64.13028,-25.11793],
  [-64.05384,-25.2154],
  [-64.02521,-25.26683],
  [-64.02083,-25.33826],
  [-64.02197,-25.36316],
  [-63.95994,-25.41492],
  [-63.78455,-25.46393],
  [-63.40226,-25.55824],
  [-63.22406,-25.63615],
  [-62.83933,-25.81356],
  [-62.50276,-25.94772],
  [-61.74188,-26.27169],
  [-60.96035,-26.6003],
  [-60.73708,-26.7028],
  [-60.41903,-26.81278],
  [-60.03801,-26.94657],
  [-59.46694,-27.13565],
  [-59.24872,-27.20804],
  [-59.09092,-27.31815],
  [-59.03473,-27.38376],
  [-58.89108,-27.44324],
  [-58.82603,-27.47708],
  [-58.77991,-27.47056],
  [-58.62283,-27.39923],
  [-58.47686,-27.37035],
  [-58.282,-27.36767],
  [-58.17459,-27.3384],
  [-58.11699,-27.34659],
  [-58.02741,-27.32221],
  [-57.66452,-27.40264],
  [-57.48417,-27.46876],
  [-57.36798,-27.44233],
  [-57.24706,-27.50385],
  [-56.9976,-27.61399],
  [-56.90322,-27.63246],
  [-56.81207,-27.63442],
  [-56.65149,-27.59165],
  [-56.5577,-27.55637],
  [-56.46503,-27.59685],
  [-56.3873,-27.61737],
  [-56.32387,-27.59431],
  [-56.09663,-27.49385],
  [-56.04036,-27.44956],
  [-55.89896,-27.4647],
  [-55.78128,-27.46823],
  [-55.70384,-27.46324],
  [-55.64133,-27.43597],
  [-55.54525,-27.33949],
  [-55.53296,-27.28825],
  [-55.50451,-27.22265],
  [-55.36191,-27.11497],
  [-55.19001,-27.01429],
  [-55.08884,-26.94799],
  [-55.06047,-26.91075],
  [-55.0399,-26.84091],
  [-54.99516,-26.82053],
  [-54.93595,-26.81112],
  [-54.90349,-26.79737],
  [-54.89688,-26.77479],
  [-54.85839,-26.71993],
  [-54.75354,-26.6606],
  [-54.72312,-26.53691],
  [-54.66857,-26.48803],
  [-54.64321,-26.47513],
  [-54.64484,-26.42712],
  [-54.62467,-26.37262],
  [-54.6152,-26.32915],
  [-54.58324,-26.15692],
  [-54.58137,-25.9822],
  [-54.57405,-25.91738],
  [-54.55387,-25.87257],
  [-54.55716,-25.83837],
  [-54.53848,-25.81441],
  [-54.53111,-25.72929],
  [-54.49874,-25.66895],
  [-54.56142,-25.60853],
  [-54.55797,-25.57927],
  [-54.51499,-25.59252],
  [-54.43953,-25.63883],
  [-54.4348,-25.69193],
  [-54.4348,-25.69193],
  [-54.43693,-25.66307],
  [-54.43814,-25.64128],
  [-54.45478,-25.63247],
  [-54.47682,-25.62417],
  [-54.49061,-25.60857],
  [-54.53579,-25.57978],
  [-54.56051,-25.58021],
  [-54.56185,-25.61181],
  [-54.51941,-25.6527],
  [-54.50043,-25.66761],
  [-54.50747,-25.67928],
  [-54.52008,-25.71657],
  [-54.53542,-25.74068],
  [-54.53869,-25.82782],
  [-54.55727,-25.84782],
  [-54.56336,-25.88267],
  [-54.56277,-25.90362],
  [-54.57722,-25.93258],
  [-54.58419,-25.99686],
  [-54.58957,-26.07078],
  [-54.58459,-26.16829],
  [-54.58732,-26.21446],
  [-54.60157,-26.24992],
  [-54.61747,-26.29373],
  [-54.62111,-26.3467],
  [-54.62843,-26.38388],
  [-54.6448,-26.42033],
  [-54.64398,-26.45153],
  [-54.64316,-26.4745],
  [-54.65679,-26.48074],
  [-54.67772,-26.49601],
  [-54.72512,-26.54759],
  [-54.73354,-26.60436],
  [-54.75306,-26.64205],
  [-54.7536,-26.65934],
  [-54.76551,-26.66776],
  [-54.79043,-26.68415],
  [-54.81274,-26.68859],
  [-54.87062,-26.72918],
  [-54.90185,-26.7842],
  [-54.91408,-26.80921],
  [-54.94915,-26.81707],
  [-55.01252,-26.82072],
  [-55.02749,-26.82199],
  [-55.04416,-26.85069],
  [-55.05871,-26.92125],
  [-55.06758,-26.9361],
  [-55.10053,-26.95814],
  [-55.12553,-26.98207],
  [-55.16491,-27.00794],
  [-55.19966,-27.01994],
  [-55.27328,-27.07203],
  [-55.31385,-27.10188],
  [-55.3712,-27.11947],
  [-55.45514,-27.17776],
  [-55.51496,-27.23221],
  [-55.53875,-27.26738],
  [-55.53366,-27.29096],
  [-55.54594,-27.32186],
  [-55.5478,-27.3492],
  [-55.59081,-27.38574],
  [-55.61185,-27.41537],
  [-55.64065,-27.43512],
  [-55.67619,-27.4606],
  [-55.7084,-27.46467],
  [-55.7918,-27.46704],
  [-55.8615,-27.45358],
  [-55.86444,-27.4922],
  [-55.84918,-27.55853],
  [-55.83651,-27.59137],
  [-55.81575,-27.6209],
  [-55.81114,-27.68269],
  [-55.78522,-27.73742],
  [-55.78903,-27.75936],
  [-55.82809,-27.7866],
  [-55.88698,-27.82412],
  [-55.91947,-27.85545],
  [-55.93961,-27.89905],
  [-55.96681,-27.93103],
  [-56.01443,-27.97547],
  [-56.02043,-28.0088],
  [-56.01318,-28.03766],
  [-56.01701,-28.07133],
  [-56.03576,-28.10452],
  [-56.05611,-28.13647],
  [-56.09521,-28.17227],
  [-56.11276,-28.22411],
  [-56.10116,-28.27218],
  [-56.10528,-28.32909],
  [-56.11144,-28.36309],
  [-56.10774,-28.45306],
  [-56.10947,-28.50632],
  [-56.10077,-28.53756],
  [-56.08086,-28.54749],
  [-56.07525,-28.55818],
  [-56.08044,-28.56898],
  [-56.09317,-28.58976],
  [-56.10478,-28.60862],
  [-56.1436,-28.649],
  [-56.1903,-28.67651],
  [-56.22352,-28.6932],
  [-56.27305,-28.70118],
  [-56.32743,-28.71949],
  [-56.35461,-28.74731],
  [-56.38629,-28.80038],
  [-56.40282,-28.88943],
  [-56.42099,-28.93429],
  [-56.44088,-28.97342],
  [-56.46411,-29.01162],
  [-56.49997,-29.0319],
  [-56.53402,-29.07326],
  [-56.59942,-29.09892],
  [-56.64052,-29.13157],
  [-56.66482,-29.1637],
  [-56.73368,-29.24404],
  [-56.79456,-29.33305],
  [-56.82824,-29.39632],
  [-56.84346,-29.42179],
  [-56.93177,-29.4618],
  [-57.00889,-29.50277],
  [-57.03522,-29.51774],
  [-57.1054,-29.52501],
  [-57.15273,-29.55066],
  [-57.20927,-29.59525],
  [-57.2208,-29.6373],
  [-57.21503,-29.68605],
  [-57.20808,-29.71136],
  [-57.23234,-29.7199],
  [-57.32839,-29.7475],
  [-57.46247,-29.82131],
  [-57.50439,-29.85911],
  [-57.53086,-29.87781],
  [-57.57784,-29.8888],
  [-57.61099,-29.90894],
  [-57.69317,-29.97762],
  [-57.75375,-30.02763],
  [-57.81323,-30.05795],
  [-57.90071,-30.06032],
  [-58.00089,-30.07566],
  [-57.99583,-30.10681],
  [-57.97001,-30.17193],
  [-57.95965,-30.26828],
  [-57.94996,-30.32354],
  [-57.96098,-30.36819],
  [-57.98299,-30.42904],
  [-57.98873,-30.49912],
  [-57.97783,-30.56264],
  [-57.9817,-30.6261],
  [-58.01225,-30.69034],
  [-58.01593,-30.74164],
  [-58.04957,-30.83419],
  [-58.04354,-30.89956],
  [-58.0534,-30.93278],
  [-58.07615,-31.01746],
  [-58.06621,-31.07446],
  [-58.08265,-31.18809],
  [-58.07758,-31.28426],
  [-58.10832,-31.42723],
  [-58.18499,-31.50911],
  [-58.20557,-31.60733],
  [-58.25351,-31.67882],
  [-58.30502,-31.74583],
  [-58.31558,-31.79963],
  [-58.32425,-31.86962],
  [-58.3233,-31.90825],
  [-58.30507,-31.95134],
  [-58.29216,-32.0052],
  [-58.24979,-32.08566],
  [-58.2349,-32.17926],
  [-58.24472,-32.20514],
  [-58.23658,-32.22369],
  [-58.24862,-32.2994],
  [-58.27592,-32.36887],
  [-58.30503,-32.47535],
  [-58.34569,-32.53928],
  [-58.41557,-32.60856],
  [-58.4531,-32.6959],
  [-58.47348,-32.73852],
  [-58.51023,-32.78164],
  [-58.55502,-32.90409],
  [-58.64956,-33.11364],
  [-58.68097,-33.20692],
  [-58.71142,-33.25018],
  [-58.71251,-33.32221],
  [-58.74769,-33.39895],
  [-58.78257,-33.42997],
  [-58.8035,-33.45492],
  [-58.79979,-33.50161],
  [-58.78962,-33.52765],
  [-58.7982,-33.58443],
  [-58.83873,-33.66979],
  [-58.85885,-33.74907],
  [-58.87964,-33.81422],
  [-58.87089,-33.89437],
  [-58.90409,-33.91884],
  [-58.94127,-33.93584],
  [-58.96488,-33.96871],
  [-58.98729,-34.0489],
  [-59.00438,-34.10931],
  [-59.01788,-34.12229],
  [-59.00369,-34.14622],
  [-58.97156,-34.17825],
  [-58.94575,-34.18854],
  [-58.91076,-34.23706],
  [-58.88285,-34.29746],
  [-58.83783,-34.32927],
  [-58.79605,-34.35894],
  [-58.74474,-34.38932],
  [-58.6844,-34.46868],
  [-58.55824,-34.49414],
  [-58.511,-34.52356],
  [-58.49408,-34.54664],
  [-58.46218,-34.53572],
  [-58.40567,-34.56863],
  [-58.38033,-34.58362],
  [-58.38253,-34.59606],
  [-58.38156,-34.60358],
  [-58.38156,-34.60358],
  [-58.3845,-34.62283],
  [-58.41625,-34.62741],
  [-58.46715,-34.64935],
  [-58.49871,-34.63555],
  [-58.53658,-34.63461],
  [-58.65675,-34.63196],
  [-58.76978,-34.63526],
  [-59.01936,-34.57749],
  [-59.13263,-34.54318],
  [-59.20616,-34.5466],
  [-59.27775,-34.53222],
  [-59.34539,-34.49754],
  [-59.39299,-34.46591],
  [-59.44981,-34.4531],
  [-59.52038,-34.46161],
  [-59.65599,-34.45798],
  [-59.72927,-34.43553],
  [-59.82834,-34.40368],
  [-59.91166,-34.43524],
  [-59.97283,-34.40972],
  [-60.03402,-34.37517],
  [-60.13277,-34.35681],
  [-60.19105,-34.34957],
  [-60.23756,-34.32137],
  [-60.25724,-34.31128],
  [-60.30637,-34.31098],
  [-60.37482,-34.29395],
  [-60.41537,-34.29667],
  [-60.45308,-34.29943],
  [-60.54336,-34.25353],
  [-60.57105,-34.22401],
  [-60.63236,-34.21724],
  [-60.69445,-34.2033],
  [-60.72477,-34.17862],
  [-60.82039,-34.09819],
  [-60.85911,-34.05367],
  [-60.96176,-33.95976],
  [-60.96439,-33.93213],
  [-60.9803,-33.93217],
  [-61.03918,-33.93241],
  [-61.07794,-33.92027],
  [-61.09737,-33.91658],
  [-61.11925,-33.89835],
  [-61.13702,-33.8781],
  [-61.17348,-33.86791],
  [-61.20347,-33.85749],
  [-61.25071,-33.81358],
  [-61.33818,-33.80709],
  [-61.35979,-33.80181],
  [-61.4967,-33.83091],
  [-61.55496,-33.84228],
  [-61.66529,-33.85235],
  [-61.93674,-33.802],
  [-61.98998,-33.73469],
  [-62.00063,-33.72459],
  [-62.08147,-33.76845],
  [-62.14172,-33.79491],
  [-62.20708,-33.83107],
  [-62.21586,-33.85986],
  [-62.23166,-33.90046],
  [-62.26263,-33.93979],
  [-62.27354,-33.96469],
  [-62.26593,-33.98676],
  [-62.27102,-34.00748],
  [-62.29871,-34.03514],
  [-62.33971,-34.07417],
  [-62.42142,-34.12431],
  [-62.44407,-34.14755],
  [-62.49212,-34.17015],
  [-62.55271,-34.19385],
  [-62.66572,-34.25387],
  [-62.68748,-34.26763],
  [-62.69576,-34.2839],
  [-62.93771,-34.23322],
  [-63.12448,-34.18856],
  [-63.33483,-34.14849],
  [-63.45865,-34.13587],
  [-63.53656,-34.12838],
  [-63.9151,-34.03715],
  [-64.00498,-34.03716],
  [-64.07573,-34.02265],
  [-64.17703,-33.98921],
  [-64.33708,-33.93596],
  [-64.38407,-33.93049],
  [-64.4067,-33.93026],
  [-64.58664,-33.92747],
  [-64.74084,-33.90622],
  [-64.98245,-33.90889],
  [-65.04305,-33.90202],
  [-65.11144,-33.87615],
  [-65.15623,-33.84911],
  [-65.28481,-33.75165],
  [-65.40489,-33.66271],
  [-65.52446,-33.62931],
  [-65.69911,-33.5482],
  [-65.99665,-33.39076],
  [-66.09417,-33.35144],
  [-66.23322,-33.31196],
  [-66.29078,-33.30582],
  [-66.30154,-33.32729],
  [-66.35988,-33.34958],
  [-66.38384,-33.3294],
  [-66.68463,-33.38651],
  [-66.87467,-33.42536],
  [-66.93571,-33.43905],
  [-67.00954,-33.4284],
  [-67.12026,-33.41144],
  [-67.18167,-33.39517],
  [-67.33988,-33.40994],
  [-67.53752,-33.44489],
  [-67.57077,-33.45916],
  [-67.66692,-33.44023],
  [-67.84043,-33.37663],
  [-67.90101,-33.34121],
  [-67.95761,-33.3267],
  [-68.08101,-33.26341],
  [-68.21524,-33.19857],
  [-68.30425,-33.13573],
  [-68.41206,-33.08464],
  [-68.4875,-33.06065],
  [-68.55179,-33.03352],
  [-68.65213,-33.00069],
  [-68.68873,-32.96848],
  [-68.76307,-32.92159],
  [-68.81995,-32.89683],
  [-68.83217,-32.94683],
  [-68.86229,-33.02818],
  [-68.87844,-33.09534],
  [-68.90824,-33.0986],
  [-68.96168,-33.09106],
  [-69.04081,-33.07731],
  [-69.10097,-33.08666],
  [-69.14669,-33.06459],
  [-69.18042,-33.03125],
  [-69.18245,-33.01305],
  [-69.17221,-32.99224],
  [-69.20356,-32.96505],
  [-69.21382,-32.95347],
  [-69.21128,-32.94028],
  [-69.22041,-32.92816],
  [-69.23418,-32.92167],
  [-69.25317,-32.92071],
  [-69.26544,-32.91226],
  [-69.26376,-32.89699],
  [-69.26914,-32.86739],
  [-69.26882,-32.84816],
  [-69.28905,-32.83251],
  [-69.30034,-32.81261],
  [-69.31333,-32.8009],
  [-69.31962,-32.77607],
  [-69.32682,-32.73532],
  [-69.33798,-32.71202],
  [-69.34969,-32.69146],
  [-69.36242,-32.68152],
  [-69.364,-32.66511],
  [-69.34982,-32.62414],
  [-69.35073,-32.59985],
  [-69.35329,-32.58936],
  [-69.3653,-32.59184],
  [-69.39774,-32.61461],
  [-69.42885,-32.6193],
  [-69.4915,-32.63093],
  [-69.5482,-32.72329],
  [-69.59284,-32.75975],
  [-69.61856,-32.78457],
  [-69.64719,-32.78912],
  [-69.68704,-32.81535],
  [-69.73849,-32.84175],
  [-69.77217,-32.86643],
  [-69.83171,-32.84178],
  [-69.93125,-32.82277],
  [-70.02796,-32.81833],
  [-70.07207,-32.80536],
  [-70.08971,-32.81409],
  [-70.09247,-32.82886],
  [-70.09097,-32.83856],
  [-70.10576,-32.84514],
  [-70.12749,-32.83666],
  [-70.13234,-32.84586],
  [-70.13497,-32.84643],
  [-70.13423,-32.84843],
  [-70.14233,-32.85811],
  [-70.14412,-32.86057],
  [-70.14689,-32.86513],
  [-70.16442,-32.86415],
  [-70.19563,-32.87062],
  [-70.22655,-32.89193],
  [-70.29919,-32.90878],
  [-70.3442,-32.92302],
  [-70.35861,-32.91614],
  [-70.36242,-32.90876],
  [-70.36504,-32.90002],
  [-70.38522,-32.88493],
  [-70.43338,-32.85999],
  [-70.48583,-32.85609],
  [-70.52088,-32.84535],
  [-70.54784,-32.83521],
  [-70.54364,-32.84071],
  [-70.52734,-32.85224],
  [-70.52164,-32.87477],
  [-70.53862,-32.89121],
  [-70.57087,-32.88861],
  [-70.59688,-32.88816],
  [-70.65702,-32.88179],
  [-70.6749,-32.89866],
  [-70.67493,-32.91935],
  [-70.68256,-32.94371],
  [-70.68469,-32.98742],
  [-70.69951,-33.04304],
  [-70.75722,-33.09609],
  [-70.7763,-33.12685],
  [-70.79587,-33.15389],
  [-70.78019,-33.18798],
  [-70.74622,-33.26408],
  [-70.7002,-33.36665],
  [-70.67491,-33.42156],
  [-70.65991,-33.43958],
  [-70.66921,-33.44888],
  [-70.66921,-33.44888],
  [-70.69956,-33.54927],
  [-70.72297,-33.69762],
  [-70.74719,-33.86049],
  [-70.7126,-33.95659],
  [-70.70437,-34.02363],
  [-70.76182,-34.07203],
  [-70.7924,-34.23174],
  [-70.97099,-34.59237],
  [-71.04134,-34.74082],
  [-71.09904,-34.83761],
  [-71.31562,-35.09019],
  [-71.51933,-35.30408],
  [-71.63133,-35.41711],
  [-71.6991,-35.57164],
  [-71.65815,-35.79646],
  [-71.64418,-35.89051],
  [-71.67597,-35.94994],
  [-71.77485,-36.05776],
  [-71.80146,-36.11339],
  [-71.81045,-36.2346],
  [-71.87153,-36.35736],
  [-72.10835,-36.58443],
  [-72.25337,-36.68616],
  [-72.32694,-36.82996],
  [-72.37184,-37.04396],
  [-72.38575,-37.12527],
  [-72.36873,-37.2374],
  [-72.38528,-37.42699],
  [-72.40547,-37.47924],
  [-72.35543,-37.52343],
  [-72.28292,-37.58285],
  [-72.27107,-37.65964],
  [-72.26994,-37.74099],
  [-72.34233,-37.81442],
  [-72.38561,-37.84215],
  [-72.39177,-37.90375],
  [-72.4405,-37.97135],
  [-72.37853,-38.06795],
  [-72.30892,-38.15835],
  [-72.36275,-38.26579],
  [-72.40871,-38.41887],
  [-72.44897,-38.61046],
  [-72.50129,-38.69109],
  [-72.59537,-38.78091],
  [-72.62388,-38.8327],
  [-72.62475,-38.92033],
  [-72.65305,-39.00256],
  [-72.67179,-39.0895],
  [-72.64552,-39.13539],
  [-72.57505,-39.23217],
  [-72.56217,-39.336],
  [-72.65708,-39.37756],
  [-72.79012,-39.44906],
  [-72.85048,-39.53241],
  [-72.94051,-39.56362],
  [-72.94742,-39.65072],
  [-72.90125,-39.77885],
  [-72.8486,-39.81682],
  [-72.79507,-39.89029],
  [-72.80565,-39.94499],
  [-72.86515,-40.09105],
  [-72.96301,-40.27518],
  [-73.00233,-40.42168],
  [-73.09642,-40.55547],
  [-73.09021,-40.58521],
  [-72.96169,-40.59174],
  [-72.75415,-40.62512],
  [-72.47365,-40.72982],
  [-72.33673,-40.69385],
  [-72.2939,-40.67581],
  [-72.16774,-40.66457],
  [-72.05258,-40.67422],
  [-71.94427,-40.71314],
  [-71.84686,-40.74738],
  [-71.77335,-40.67981],
  [-71.69976,-40.69464],
  [-71.6667,-40.74662],
  [-71.55963,-40.83089],
  [-71.47051,-40.89721],
  [-71.40216,-40.93645],
  [-71.34408,-40.99128],
  [-71.25885,-41.03737],
  [-71.21097,-41.03767],
  [-71.15918,-41.0644],
  [-71.22675,-41.11743],
  [-71.31305,-41.17856],
  [-71.39492,-41.19499],
  [-71.42955,-41.23412],
  [-71.4972,-41.27776],
  [-71.5148,-41.35612],
  [-71.47003,-41.50995],
  [-71.45801,-41.53122],
  [-71.49586,-41.57324],
  [-71.45615,-41.66278],
  [-71.45081,-41.71963],
  [-71.4424,-41.75313],
  [-71.42352,-41.82362],
  [-71.4959,-41.89221],
  [-71.52436,-41.9837],
  [-71.54594,-42.0345],
  [-71.51794,-42.06731],
  [-71.41696,-42.13266],
  [-71.39675,-42.19881],
  [-71.35458,-42.24747],
  [-71.35245,-42.29236],
  [-71.26189,-42.29482],
  [-71.10797,-42.34991],
  [-71.09403,-42.48768],
  [-71.07833,-42.61979],
  [-71.08057,-42.65648],
  [-71.04859,-42.66928],
  [-71.01195,-42.70192],
  [-71.09607,-42.73409],
  [-71.1668,-42.92635],
  [-71.215,-42.95078],
  [-71.15936,-42.96885],
  [-70.94888,-43.06291],
  [-70.83864,-43.15295],
  [-70.85333,-43.22889],
  [-70.88093,-43.28111],
  [-70.87864,-43.35449],
  [-70.88605,-43.42105],
  [-70.83016,-43.47253],
  [-70.80299,-43.54823],
  [-70.84013,-43.62051],
  [-70.85299,-43.69199],
  [-70.93367,-43.77642],
  [-70.89215,-43.95471],
  [-70.77203,-44.01131],
  [-70.55296,-44.0661],
  [-70.43238,-44.21768],
  [-70.40114,-44.29471],
  [-70.44148,-44.38628],
  [-70.42863,-44.48765],
  [-70.36045,-44.60441],
  [-70.11688,-44.83254],
  [-70.02383,-45.03929],
  [-69.96122,-45.21243],
  [-69.88941,-45.38534],
  [-69.8295,-45.46984],
  [-70.05622,-45.46099],
  [-70.25499,-45.65612],
  [-70.24795,-45.67631],
  [-70.32547,-45.81849],
  [-70.4057,-45.93929],
  [-70.53355,-46.09365],
  [-70.70552,-46.23844],
  [-70.75283,-46.30172],
  [-70.73577,-46.40401],
  [-70.7278,-46.43948],
  [-70.75899,-46.51773],
  [-70.85413,-46.55885],
  [-70.94244,-46.58343],
  [-70.78555,-46.79983],
  [-70.69721,-46.94537],
  [-70.69636,-47.02523],
  [-70.81116,-47.06104],
  [-70.83277,-47.10476],
  [-70.8655,-47.12985],
  [-70.85431,-47.18224],
  [-70.95232,-47.2576],
  [-71.00254,-47.3337],
  [-70.98392,-47.39464],
  [-70.93617,-47.41475],
  [-70.9378,-47.45825],
  [-70.92838,-47.51151],
  [-70.8012,-47.64333],
  [-70.82356,-47.79687],
  [-70.84957,-47.8612],
  [-70.95443,-47.97296],
  [-71.1907,-48.19776],
  [-71.1792,-48.25001],
  [-70.91421,-48.321],
  [-70.54425,-48.40983],
  [-70.5273,-48.46352],
  [-70.59063,-48.54306],
  [-70.71268,-48.65678],
  [-70.73788,-48.71486],
  [-70.75849,-48.80074],
  [-71.00301,-48.89775],
  [-71.02469,-48.97272],
  [-71.06158,-49.05108],
  [-71.23707,-49.12542],
  [-71.35493,-49.2197],
  [-71.33032,-49.26799],
  [-71.41587,-49.33611],
  [-71.49586,-49.3664],
  [-71.53851,-49.42265],
  [-71.52201,-49.48962],
  [-71.47545,-49.5341],
  [-71.42669,-49.59384],
  [-71.46116,-49.59919],
  [-71.54098,-49.63751],
  [-71.81249,-49.69492],
  [-71.95799,-49.72882],
  [-72.05152,-49.84041],
  [-72.04497,-49.89095],
  [-72.0822,-49.9504],
  [-72.15344,-50.01866],
  [-72.12257,-50.08372],
  [-71.8998,-50.2459],
  [-71.87388,-50.28995],
  [-71.87019,-50.31473],
  [-71.94396,-50.31897],
  [-72.09255,-50.31411],
  [-72.1947,-50.32451],
  [-72.26479,-50.338],
  [-72.26479,-50.338],
  [-72.23852,-50.3422],
  [-72.21932,-50.33178],
  [-72.1674,-50.31619],
  [-72.11773,-50.3218],
  [-72.06329,-50.31118],
  [-71.97378,-50.32064],
  [-71.90764,-50.31729],
  [-71.76185,-50.29909],
  [-71.68607,-50.30055],
  [-71.65378,-50.31843],
  [-71.64846,-50.33386],
  [-71.62084,-50.34319],
  [-71.5995,-50.34648],
  [-71.56531,-50.34857],
  [-71.52153,-50.34262],
  [-71.50682,-50.35547],
  [-71.51221,-50.40287],
  [-71.50445,-50.43529],
  [-71.47484,-50.45374],
  [-71.45002,-50.50073],
  [-71.43797,-50.54127],
  [-71.41423,-50.58755],
  [-71.37438,-50.61819],
  [-71.31221,-50.63821],
  [-71.25544,-50.68217],
  [-71.21427,-50.71539],
  [-71.1511,-50.77566],
  [-71.11094,-50.83682],
  [-71.00741,-50.95509],
  [-70.97053,-51.00426],
  [-70.91828,-51.02815],
  [-70.86334,-51.01708],
  [-70.80833,-51.01215],
  [-70.77328,-51.03139],
  [-70.6866,-51.04939],
  [-70.64621,-51.0574],
  [-70.60509,-51.08392],
  [-70.57152,-51.11369],
  [-70.54116,-51.14899],
  [-70.48341,-51.21331],
  [-70.41927,-51.27786],
  [-70.36919,-51.30271],
  [-70.33947,-51.33095],
  [-70.288,-51.3559],
  [-70.25174,-51.36763],
  [-70.23701,-51.38681],
  [-70.19303,-51.40893],
  [-70.19875,-51.42269],
  [-70.14904,-51.47623],
  [-70.07976,-51.51589],
  [-70.05435,-51.54171],
  [-69.99009,-51.56871],
  [-69.91974,-51.59681],
  [-69.84363,-51.60397],
  [-69.72862,-51.62423],
  [-69.67574,-51.614],
  [-69.61138,-51.62923],
  [-69.57274,-51.63352],
  [-69.47434,-51.62179],
  [-69.29987,-51.62144],
  [-69.29992,-51.64532],
  [-69.29079,-51.68505],
  [-69.27849,-51.69545],
  [-69.29407,-51.71631],
  [-69.35103,-51.76917],
  [-69.39233,-51.82383],
  [-69.38972,-51.84605],
  [-69.40683,-51.86657],
  [-69.43796,-51.86513],
  [-69.47092,-51.88337],
  [-69.51528,-51.91965],
  [-69.52345,-51.96143],
  [-69.53451,-52.06799],
  [-69.5307,-52.10075],
  [-69.52428,-52.11508],
  [-69.51974,-52.1388],
  [-69.49751,-52.16306],
  [-69.47105,-52.22604],
  [-69.48664,-52.24138],
  [-69.56915,-52.27737],
  [-69.57457,-52.28735],
  [-69.63359,-52.3168],
  [-69.67236,-52.32327],
  [-69.69548,-52.34502],
  [-69.73464,-52.3724],
  [-69.7365,-52.38353],
  [-69.7393,-52.38618],
  [-69.75001,-52.40231],
  [-69.69854,-52.41534],
  [-69.66156,-52.42625],
  [-69.55464,-52.45281],
  [-69.52461,-52.49421],
  [-69.5203,-52.50261],
  [-69.4904,-52.52308],
  [-69.44717,-52.53287],
  [-69.39141,-52.5343],
  [-69.36535,-52.55893],
  [-69.36884,-52.64442],
  [-69.33935,-52.6635],
  [-69.33851,-52.7045],
  [-69.35679,-52.74918],
  [-69.39354,-52.78744],
  [-69.41477,-52.82678],
  [-69.40629,-52.84652],
  [-69.36229,-52.87534],
  [-69.34202,-52.89369],
  [-69.33115,-52.91704],
  [-69.32343,-52.92955],
  [-69.32836,-52.94055],
  [-69.33801,-52.95891],
  [-69.32798,-52.98485],
  [-69.32926,-52.99855],
  [-69.33642,-53.0144],
  [-69.33135,-53.02529],
  [-69.33966,-53.04023],
  [-69.35407,-53.05701],
  [-69.35119,-53.06918],
  [-69.34209,-53.10085],
  [-69.32338,-53.133],
  [-69.27431,-53.19041],
  [-69.2572,-53.19877],
  [-69.25669,-53.21199],
  [-69.24548,-53.21906],
  [-69.23072,-53.23539],
  [-69.2215,-53.24508],
  [-69.23286,-53.26247],
  [-69.22984,-53.29559],
  [-69.22171,-53.31593],
  [-69.25628,-53.35064],
  [-69.1503,-53.347],
  [-68.95643,-53.32003],
  [-68.93556,-53.31484],
  [-68.88324,-53.31705],
  [-68.78451,-53.32527],
  [-68.65538,-53.32221],
  [-68.56693,-53.30993],
  [-68.5074,-53.30567],
  [-68.45231,-53.29973],
  [-68.43076,-53.31063],
  [-68.37201,-53.32965],
  [-68.3458,-53.3436],
  [-68.32263,-53.37098],
  [-68.25461,-53.4009],
  [-68.17297,-53.43834],
  [-68.09602,-53.50263],
  [-68.07268,-53.50319],
  [-68.0151,-53.56471],
  [-67.97524,-53.59994],
  [-67.95258,-53.63678],
  [-67.92821,-53.68256],
  [-67.85896,-53.69271],
  [-67.79405,-53.72555],
  [-67.76806,-53.76142],
  [-67.77795,-53.78531],
  [-67.77897,-53.80718],
  [-67.80382,-53.82286],
  [-67.79984,-53.83166],
  [-67.76557,-53.84905],
  [-67.67921,-53.8716],
  [-67.59764,-53.91767],
  [-67.53163,-53.94338],
  [-67.49269,-53.943],
  [-67.44868,-53.98293],
  [-67.3503,-54.01372],
  [-67.3445,-54.03502],
  [-67.32483,-54.05031],
  [-67.30566,-54.10494],
  [-67.26969,-54.12782],
  [-67.24883,-54.16563],
  [-67.2162,-54.20453],
  [-67.20806,-54.22675],
  [-67.1976,-54.23933],
  [-67.19759,-54.25509],
  [-67.21352,-54.30075],
  [-67.22595,-54.32987],
  [-67.25018,-54.35145],
  [-67.24785,-54.36715],
  [-67.25033,-54.3861],
  [-67.23919,-54.40196],
  [-67.21004,-54.42486],
  [-67.20518,-54.44925],
  [-67.19512,-54.46899],
  [-67.18192,-54.49397],
  [-67.20769,-54.53724],
  [-67.20406,-54.54933],
  [-67.22472,-54.55472],
  [-67.26082,-54.56659],
  [-67.32201,-54.58297],
  [-67.36475,-54.59122],
  [-67.3865,-54.60486],
  [-67.4086,-54.61912],
  [-67.42603,-54.61519],
  [-67.48795,-54.61781],
  [-67.55878,-54.61852],
  [-67.63426,-54.59706],
  [-67.68435,-54.59904],
  [-67.72628,-54.61398],
  [-67.79119,-54.65226],
  [-67.79646,-54.67315],
  [-67.80929,-54.68542],
  [-67.84186,-54.68958],
  [-67.84542,-54.69597],
  [-67.83464,-54.70869],
  [-67.82572,-54.72238],
  [-67.82928,-54.73646],
  [-67.81494,-54.74097],
  [-67.8296,-54.74098],
  [-67.90866,-54.73226],
  [-67.98002,-54.72936],
  [-68.04058,-54.7152],
  [-68.09664,-54.71673],
  [-68.16362,-54.72297],
  [-68.19034,-54.7404],
  [-68.1961,-54.75204],
  [-68.19875,-54.76907],
  [-68.21209,-54.78023],
  [-68.22195,-54.78681],
  [-68.25488,-54.78909],
  [-68.29821,-54.79839],
  [-68.32415,-54.80548]
]